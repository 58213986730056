<template>
  <div
    style="width: 100%; height: 100%; position: fixed; z-index: 1;"
    @drop.prevent="handleDrop"
    @dragover.prevent="droppingFile = true"
    @dragenter.prevent="droppingFile = true"
    @dragleave.prevent="droppingFile = false"
  >
    <v-app
      id="app"
      style="height: 100%; width: 100%; position: fixed; background-color: var(--headerColor); transition: 0.2s;"
    >
      <v-card
        v-if="$root.showMemory"
        style="z-index: 999; position: fixed; transition: 0.3s;"
        :style="{
          top: memoryPosition.startsWith('top') ? '16px' : 'auto',
          bottom: memoryPosition.startsWith('bottom') ? '16px' : 'auto',
          left: memoryPosition.endsWith('left') ? '16px' : 'auto',
          right: memoryPosition.endsWith('right') ? '16px' : 'auto',
        }"
        class="pa-2 ma-0"
      >
        <div class="d-flex">
          <h4
            v-if="$root.memoryHistory.length"
            class="mb-2"
          >
            {{ ($root.memoryHistory[$root.memoryHistory.length - 1].usedJSHeapSize / 1000000).toFixed() }} / {{ ($root.memoryHistory[$root.memoryHistory.length - 1].totalJSHeapSize / 1000000).toFixed() }} MB ({{ ($root.memoryHistory[$root.memoryHistory.length - 1].usedJSHeapSize / $root.memoryHistory[$root.memoryHistory.length - 1].totalJSHeapSize * 100).toFixed() }}%)
          </h4>

          <v-spacer />
          <div class="mt-n2">
            <div style="height: 14px;">
              <v-icon
                class="ma-0"
                size="14"
                @click="memoryPosition = 'topleft'"
              >
                mdi-pan-top-left
              </v-icon>
              <v-icon
                class="ma-0"
                size="14"
                @click="memoryPosition = 'topright'"
              >
                mdi-pan-top-right
              </v-icon>
            </div>
            <div style="height: 14px;">
              <v-icon
                class="ma-0"
                size="14"
                @click="memoryPosition = 'bottomleft'"
              >
                mdi-pan-bottom-left
              </v-icon>
              <v-icon
                class="ma-0"
                size="14"
                @click="memoryPosition = 'bottomright'"
              >
                mdi-pan-bottom-right
              </v-icon>
            </div>
          </div>
        </div>
        
        <div
          style="height: 240px; border-radius: 8px; overflow: hidden;"
          :style="{ width: ($root.memorySamples * 1) + 'px'}"
        >
          <div
            v-for="i in $root.memorySamples"
            :key="i"
            style="width: 1px; height: 100%; float: left; background-color: #0001; position: relative;"
            :style="{
              borderRight: $root.memoryHistory[i-1]?.event ? '1px solid red' : 'none',
            }"
          >
            <div
              style="width: 100%; background-color: #546E7A; position: absolute; bottom: 0; left: 0;"
              :style="{ height: ($root.memoryHistory[i-1]?.jsHeapSizeLimit / $root.maxMemorySample) * 100 + '%' }"
            />
            <div
              style="width: 100%; background-color: #00BCD4; position: absolute; bottom: 0; left: 0;"
              :style="{ height: ($root.memoryHistory[i-1]?.totalJSHeapSize / $root.maxMemorySample) * 100 + '%' }"
            />
            <div
              style="width: 100%; background-color: #4CAF50; position: absolute; bottom: 0; left: 0;"
              :style="{ height: ($root.memoryHistory[i-1]?.usedJSHeapSize / $root.maxMemorySample) * 100 + '%' }"
            />
          </div>
        </div>
      </v-card>
      <v-dialog
        v-if="$root.isStaff && !$root.showInviteDialog && !showProfileUpdate"
        v-model="onboardingDialog"
        width="450"
        persistent
      >
        <catalog-configurator
          style="max-height: 90vh;"
          @close="onboardingDialog = false"
        />
      </v-dialog>

      <v-dialog
        v-if="$root.isStaff && needsAddressSetup && !$root.showInviteDialog && !showProfileUpdate && !onboardingDialog"
        v-model="addressSetupDialog"
        width="450"
        persistent
      >
        <address-registration-card @close="addressSetupDialog = false" />
      </v-dialog>

      <v-dialog
        v-if="$root.isStaff && billingUpdateRequired && !addressSetupDialog && !$root.showInviteDialog && !showProfileUpdate && !onboardingDialog && !$root.adjustingCustomPricing"
        v-model="billingUpdateDialog"
        width="450"
        persistent
      >
        <v-card
          :loading="!$root.formfactoriesPlan"
          class="pb-2"
        >
          <v-card-text v-if="$root.formfactoriesPlan">
            <h3>Billing Update</h3>
            <h4 class="text--secondary">
              Your formfactories plan has been updated to:
            </h4>

            <v-card class="px-6 py-4 mt-8">
              <h2 :style="{ color: $root.formfactoriesPlan.color }">
                {{ $root.formfactoriesPlan.name }}
              </h2>
              <h4>${{ $root.formfactoriesPlanPrice }} per month</h4>
            </v-card>
          </v-card-text>
          <v-card-actions v-if="$root.formfactoriesPlan">
            <v-btn
              color="red"
              text
              @click="billingUpdateDialog = false"
            >
              Close
            </v-btn>
            <v-spacer />
            <v-btn
              color="var(--highlightColor)"
              text
              :loading="fixingBillingUpdate"
              @click="fixBillingUpdate"
            >
              Continue
              <v-icon right>
                fas fa-chevron-right
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-if="!$root.showInviteDialog"
        v-model="showProfileUpdate"
        persistent
        width="500"
      >
        <v-card
          v-if="$root.userProfile"
          class="ma-0"
          color="var(--paneColor2)"
        >
          <v-card-text class="pb-0">
            <h3 class="mb-6 ml-8">
              Update Your {{ $root.enterprise.name }} Profile
            </h3>
            <account
              title=""
              disable-delete
            />
          </v-card-text>
          <v-card-actions class="py-4">
            <v-spacer />
            <v-btn
              color="var(--highlightColor)"
              :disabled="!$root.userProfile.fullName"
              text
              @click="showProfileUpdate = false"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <staff-side-bar
        v-if="$root.isStaff && !$root.hideHeaders"
        v-model="$root.showStaffSideBar"
      />

      <navigation v-if="$root.showHeader" />

      <v-main
        :style="{
          paddingRight: $root.isStaff && !$root.hideHeaders && !$vuetify.breakpoint.mobile ? '16px' : '0px',
          paddingBottom: $root.isStaff && !$root.hideHeaders && !$vuetify.breakpoint.mobile ? '16px' : '0px',
        }"
        style="transition: 0.3s; height: calc(100% - 50px - max(0px, env(safe-area-inset-top))); position: relative;"
      >
        <div
          :style="{
            borderRadius: $root.isStaff && !$root.hideHeaders ? ($vuetify.breakpoint.mobile ? ($vuetify.breakpoint.xs ? '0' : '32px 0 0 0') : '32px') : '0px',
            border: $root.isStaff && !$root.hideHeaders && !$vuetify.breakpoint.mobile ? '1px solid #0001' : 'none',
          }"
          style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; overflow: hidden;"
        >
          <content-background
            :theme="$root.theme"
            style="transition: 0.3s;"
          />
          <div
            id="router-content"
            ref="routerContent"
            style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; overflow: auto;"
            :style="{
              borderRadius: $root.isStaff && !$root.hideHeaders ? ($vuetify.breakpoint.mobile ? '0' : '32px') : '0px',
            }"
          >
            <router-view
              class="d-flex flex-column"
              style="width: 100%;"
            />
          </div>
          <global-chat-opener v-if="($root.isStaff || $root.isAlumni) && !($route.name === 'Counting' && $root.hideHeaders)" />
        </div>
      </v-main>

      <v-snackbar
        bottom
        :value="updateExists && (($root.isStaff && $root.enterprise.enableStaffUpdatePrompt) || (!$root.isStaff && $root.enterprise.enableCustomerUpdatePrompt)) && satisfiedMinimumVersion"
        :timeout="-1"
        color="var(--highlightColor)"
      >
        <v-icon
          small
          left
        >
          fas fa-sync-alt
        </v-icon>New update available
        <template #action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="refreshApp"
          >
            Update
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog
        max-width="300"
        :value="updateExists && (($root.isStaff && $root.enterprise.enableStaffUpdatePrompt) || (!$root.isStaff && $root.enterprise.enableCustomerUpdatePrompt)) && !satisfiedMinimumVersion"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5">
            Website must be updated to continue
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="var(--highlightColor)"
              text
              @click="refreshApp"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <email-editor v-if="$root.canUseEmailEditor" />

      <v-dialog
        v-model="$root.showTaskReader"
        width="1200"
        style="overflow: visible;"
        content-class="show-overflow"
      >
        <task-reader />
      </v-dialog>
      

      <v-dialog
        v-if="$root.organizationPreviewID"
        :key="'orgdialog_' + $root.organizationPreviewID"
        v-model="$root.organizationPreviewOpen"
        content-class="pa-0"
        max-width="1000"
      >
        <organization
          style="padding: 0 !important;"
          :uuid="$root.organizationPreviewID"
        />
      </v-dialog>

      <v-dialog
        v-if="$root.userPreviewEmail"
        :key="'userdialog_' + $root.userPreviewEmail"
        v-model="$root.userPreviewOpen"
        content-class="pa-0"
        max-width="1200"
      >
        <user
          style="padding: 0 !important;"
          :email="$root.userPreviewEmail"
        />
      </v-dialog>

      <v-dialog
        v-if="$root.projectPreviewID"
        :key="'projectdialog_' + $root.projectPreviewID"
        v-model="$root.projectPreviewOpen"
        content-class="pa-0"
        max-width="1320"
      >
        <project-page
          style="padding: 0 !important; background-color: var(--paneColor2); height: 80vh;"
          :uuid="$root.projectPreviewID"
        />
      </v-dialog>

      <v-dialog
        v-if="$root.jobPreviewID"
        v-model="$root.jobPreviewOpen"
        content-class="pa-0 ma-0"
        max-width="1000"
      >
        <job-preview
          v-if="$root.jobPreviewData"
          :item="$root.jobPreviewData"
          :item-id="$root.jobPreviewID"
        />
        <v-card
          v-else
          class="ma-0"
        >
          <v-progress-circular
            indeterminate
            class="ma-16"
            color="var(--highlightColor)"
          />
        </v-card>
      </v-dialog>

      <v-fade-transition>
        <v-btn
          v-if="$root.projectPreviewOpen"
          style="position: fixed; top: 8px; right: 8px; z-index: 1000000;"
          fab
          x-small
          class="ma-0"
          @click="$root.projectPreviewOpen = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-fade-transition>

      <v-snackbar
        v-model="$root.showAutoEmailConfirmation"
        color="var(--highlightColor)"
        timeout="-1"
      >
        <h4>Send email template "{{ $root.autoEmailConfirmation ? $root.autoEmailConfirmation.template.name : null }}" for project {{ $root.autoEmailConfirmation ? $root.autoEmailConfirmation.projectLabel : null }}?</h4>
        <template #action="{ attrs }">
          <v-btn
            color="red"
            dark
            width="120"
            class="mx-1"
            v-bind="attrs"
            @click="$root.autoEmailConfirmation = null"
          >
            <v-icon left>
              mdi-close
            </v-icon>
            Cancel
          </v-btn>
          <v-btn
            color="amber"
            dark
            width="120"
            class="mx-1"
            v-bind="attrs"
            @click="$root.editQueuedEmail()"
          >
            <v-progress-circular
              v-if="$root.autoEmailConfirmationDownloading"
              indeterminate
              size="20"
              color="white"
            />
            <div v-else>
              <v-icon left>
                fas fa-pencil-alt
              </v-icon>
              Edit
            </div>
          </v-btn>
          <v-btn
            color="blue"
            dark
            width="120"
            class="mx-1"
            v-bind="attrs"
            @click="$root.sendQueuedEmail()"
          >
            <v-progress-circular
              v-if="$root.autoEmailConfirmationSending"
              indeterminate
              size="20"
              color="white"
            />
            <div v-else>
              <v-icon left>
                fas fa-paper-plane
              </v-icon>
              Send
            </div>
          </v-btn>
        </template>
      </v-snackbar>

      <div
        v-if="!$root.isStaff && $root.projectPreview && $router.currentRoute.name !== 'ProjectPage'"
        id="project-preview"
        rounded="lg"
        style="position: fixed; width: 100%; height: calc(100vh - 110px); transition: 0.3s;"
        :style="{ top: $root.showProjectPreview ? '110px' : '100%' }"
      >
        <div
          style="position: fixed; transition: 0.3s; height: calc(100vh - 50px); top: 50px; width: 100%; background-color: #000; z-index: -1;"
          :style="{
            opacity: $root.showProjectPreview ? 0.3 : 0,
            pointerEvents: $root.showProjectPreview ? '' : 'none'
          }"
          @click="$root.showProjectPreview = false"
        />
        <div
          v-if="$root.projectPreviewNumItems || $root.showProjectPreview"
          class="d-flex"
          style="justify-content: space-around; position: absolute; top: -40px; height: 40px; width: 100%; z-index: -1;"
        >
          <div
            style="cursor: pointer"
            class="d-flex"
            @click="$root.showProjectPreview = !$root.showProjectPreview"
          >
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  :to="'/projects/' + $root.projectPreview"
                  target="_blank"
                  rounded
                  small
                  style="margin-left: 44px"
                  class="mt-1 mr-2"
                  height="32"
                  color="var(--highlightColor)"
                  v-bind="attrs"
                  :style="{
                    opacity: $root.showProjectPreview ? 1 : 0,
                    pointerEvents: $root.showProjectPreview ? 'all' : 'none'
                  }"
                  v-on="on"
                >
                  <v-icon color="white">
                    mdi-open-in-new
                  </v-icon>
                </v-btn>
              </template>
              <span>Open Project in New Tab</span>
            </v-tooltip>
            <v-card
              style="background-color: var(--highlightColor); width: 320px; text-align: center; border-radius: 16px 16px 0 0 !important;"
              class="ma-0 pa-2"
              rounded="xl"
              elevation="12"
            >
              <h4
                v-if="$root.enterprise.demoMode && $root.enterprise.demoStyle === 'Drawer'"
                class="white--text"
              >
                <v-icon
                  left
                  color="white"
                >
                  mdi-shimmer
                </v-icon>
                Demo Mode
              </h4>
              <h4
                v-else
                style="color: white"
              >
                <v-icon
                  left
                  color="white"
                >
                  {{ $root.projectPreviewNumItems ? 'fas fa-shopping-cart' : 'fas fa-upload' }}
                </v-icon>
                {{ $root.showProjectPreview ? ($root.projectPreviewNumItems ? 'Hide Cart' : 'Hide') : ($root.projectPreviewNumItems ? 'Edit Cart (' + $root.projectPreviewNumItems + ')' : 'Submit Files') }}
                <v-icon
                  right
                  color="white"
                >
                  {{ $root.showProjectPreview ? 'fas fa-chevron-down' : 'fas fa-chevron-up' }}
                </v-icon>
              </h4>
            </v-card>
            <v-btn
              to="/submit"
              target="_blank"
              rounded
              small
              class="mt-1 ml-2 mr-0"
              height="32"
              style="color: white;"
              color="var(--highlightColor)"
              :style="{
                opacity: $root.showProjectPreview ? 1 : 0,
                pointerEvents: $root.showProjectPreview ? 'all' : 'none'
              }"
            >
              <v-icon
                left
                color="white"
              >
                mdi-plus
              </v-icon> New Cart
            </v-btn>
          </div>
        </div>
        <div
          style="border-radius: 16px 16px 0 0; overflow: auto; height: 100%; background-color: white;"
        >
          <content-background
            style="border-radius: 16px 16px 0 0;"
            :theme="$root.theme"
          />
          <project-page
            v-if="$root.projectPreview"
            :uuid="$root.projectPreview"
            disable-title
            :files-to-process="droppedFiles"
            @num-items="$root.projectPreviewNumItems = $event"
          />
        </div>
      </div>

      <div id="errors">
        <v-snackbar
          v-for="error in $root.errors"
          :key="error.id"
          :color="error.type === 'success' ? 'green' : (error.type === 'info' ? 'blue' : (error.type === 'warning' ? 'orange' : 'red'))"
          :value="true"
          absolute
          bottom
        >
          {{ error.message }}
          <template #action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="$root.errors = $root.errors.filter(e => e.id !== error.id)"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>

      <v-dialog
        v-model="$root.forceEnterpriseOverrideSelection"
        persistent
        fullscreen
      >
        <div style="background-color: var(--paneColor2); height: 100vh; width: 100vw; display: flex; justify-content: center; align-items: center;">
          <v-card
            width="280"
            style="background-color: var(--paneColor1);"
          >
            <div class="px-8 py-4">
              <h3>Welcome to Lana!</h3>
              <h4>Select an enterprise below</h4>
            </div>
            <div class="pb-4">
              <v-list color="transparent">
                <v-list-item
                  v-for="enterprise in $root.staffEnterprises"
                  :key="enterprise.value"
                  color="red"
                  class="px-8"
                  @click="$root.enterpriseOverride = enterprise.value"
                >
                  <v-list-item-title>{{ enterprise.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </v-card>
        </div>
      </v-dialog>

      <v-fade-transition>
        <div
          v-if="!$root.isStaff && ['TaskPageHome', 'TaskPage', 'MaterialPage', 'AllProducts', 'ProductVariantPage', 'CategoryProducts', 'MyProjectsPage'].includes($route.name) && droppingFile"
          style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; color: white; text-align: center;"
          class="d-flex justify-center align-center"
        >
          <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: var(--highlightColor); opacity: 0.8;" />
          <v-scale-transition>
            <div
              v-if="droppingFile"
              style="pointer-events: none; z-index: 1;"
            >
              <em
                style="font-size: 72px; margin-bottom: 16px;"
                class="fas fa-cloud-upload-alt"
              />
              <h2>Drop Files Anywhere</h2>
              <h5 v-if="$root.isStaff">
                Accepted types: .stl .obj .zip, or any attachment
              </h5>
            </div>
          </v-scale-transition>
        </div>
      </v-fade-transition>

      <v-dialog
        v-model="$root.machinePanelOpen"
        width="900"
        height="600"
      >
        <machine-panel
          :id="$root.machinePanelID"
          width="900"
          height="600"
          @close="$root.machinePanelOpen = false"
        />
      </v-dialog>

      <v-dialog
        v-model="$root.showInviteDialog"
        width="400"
        persistant
      >
        <v-card>
          <v-card-text>
            <h3>You've been invited to join</h3>
            <h2 class="mt-n2">
              {{ $root.enterprise.name }}
            </h2>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="red"
              text
              :disabled="$root.acceptingStaffInvite"
              @click="$root.showInviteDialog = false"
            >
              Ignore
            </v-btn>
            <v-spacer />
            <v-btn
              color="green"
              text
              :loading="$root.acceptingStaffInvite"
              @click="acceptInvite"
            >
              Join Team
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
  import update from './mixins/update'
  import helpers from './mixins/helpers'
  import Navigation from '@/components/Navigation'

  // Setup main page component
  export default {
    name: 'LanaApp',
    components: {
      Navigation,
      GlobalChatOpener: () => import('@/components/extras/GlobalChatOpener'),
      EmailEditor: () => import('@/components/extras/EmailEditor'),
      TaskReader: () => import('@/components/extras/TaskReader'),
      Organization: () => import('@/components/staff/Organization'),
      User: () => import('@/components/staff/User'),
      ProjectPage: () => import('@/components/project/ProjectPage'),
      ContentBackground: () => import('@/components/extras/ContentBackground.vue'),
      StaffSideBar: () => import('@/components/extras/StaffSideBar.vue'),
      Account: () => import('@/components/extras/Account.vue'),
      MachinePanel: () => import('@/components/extras/MachinePanel.vue'),
      CatalogConfigurator: () => import('@/components/staff/CatalogConfigurator'),
      AddressRegistrationCard: () => import('@/components/staff/AddressRegistrationCard'),
      JobPreview: () => import('@/components/project/JobPreview'),
    },
    mixins: [update, helpers],
    data: function () {
      return {
        showStaffSideBar: false,
        drawerOpen: true,
        navExpanded: null,
        showProfileUpdate: false,
        droppingFile: false,
        droppedFiles: [],
        onboardingDialog: false,
        addressSetupDialog: false,
        billingUpdateDialog: false,
        fixingBillingUpdate: false,
        memoryPosition: 'topleft',
      }
    },
    computed: {
      billingUpdateRequired: function () {
        const subscription = this.$root.enterprise.stripeSubscription
        if (!this.$root.formfactoriesPlanPrice) return false
        if (!subscription) return true
        if (subscription.status !== 'active') return true
        if (String(subscription?.plan?.amount || 0) !== ((this.$root.formfactoriesPlanPrice || 0) * 100).toFixed()) return true
        return false
      },
      needsAddressSetup () {
        return !this.$root.enterprise.address && (this.$root.configPublic.enableProjectPricing || this.$root.configPublic.enableStripe || this.$root.configPublic.enableShippo)
      },
      needsMaterialsSetup () {
        if (!this.$root.production || !this.$root.production.catalog) return false
        return !this.$root.production.catalog.find(x => x.name === 'Material').children.filter(x => x.active).length
      },
      needsProfileUpdate () {
        return this.$root.currentUser && this.$root.userProfile && !this.$root.userProfile.fullName
      },
      needsForceRefresh () {
        return this.$root.configPublic && this.$root.configPublic.forceRefreshMinimumVersion && this.updateExists && ((this.$root.isStaff && this.$root.configPublic.enableStaffUpdatePrompt) || (!this.$root.isStaff && this.$root.configPublic.enableCustomerUpdatePrompt)) && !this.satisfiedMinimumVersion
      },
      satisfiedMinimumVersion () {
        if (!this.$root.configPublic || !this.$root.configPublic.minimumVersion) return true

        const minSegs = this.$root.configPublic.minimumVersion.split('.')
        const curSegs = this.$root.version.split('.')
        for (let i = 0; i < Math.min(minSegs.length, curSegs.length); i++) {
          if (+curSegs[i] > +minSegs[i]) return true
          if (+curSegs[i] < +minSegs[i]) return false
        }
        return true
      },
    },
    watch: {
      billingUpdateRequired: {
        handler: function (val) {
          this.billingUpdateDialog = true
        },
        immediate: true,
      },
      needsMaterialsSetup: {
        handler: function (val) {
          if (val) this.onboardingDialog = true
        },
        immediate: true,
      },
      needsAddressSetup: {
        handler: function (newVal, oldVal) {
          if (newVal) this.addressSetupDialog = true
          else if (oldVal) this.addressSetupDialog = false
        },
        immediate: true,
      },
      droppedFiles: {
        handler: function (val) {
          if (val.length) {
            this.$root.showProjectPreview = true
          }
        },
        immediate: true,
      },
      needsProfileUpdate: {
        handler: function (val) {
          if (val) this.showProfileUpdate = true
        },
        immediate: true,
      },
      needsForceRefresh: {
        handler: function (val) {
          if (val) this.refreshApp()
        },
        immediate: true,
      },
    },
    mounted: function () {

      // Disable scrolling on number inputs
      document.addEventListener('wheel', function () {
          if (document.activeElement.type === 'number') {
              document.activeElement.blur()
          }
      })

      // Keep track of pressed keys
      document.addEventListener('keydown', (e) => {
        this.$root.$emit('keydown', e)
      })
      document.addEventListener('keyup', (e) => {
        this.$root.$emit('keyup', e)
      })

      // Keep track of user scroll of router-content
      const debounce = (fn) => {
        let frame
        return (...params) => {
          if (frame) cancelAnimationFrame(frame)
          frame = requestAnimationFrame(() => { fn(...params) })
        }
      }

      // Store scroll position in root variable
      const storeScroll = () => { this.$root.pageScroll = this.$refs.routerContent.scrollTop }
      this.$refs.routerContent.addEventListener('scroll', debounce(storeScroll), { passive: true })
      storeScroll()
    },
    methods: {
      fixBillingUpdate: function () {
        // console.log('price should be', this.$root.formfactoriesPlanPrice)
        // console.log('subscription exists', !!this.$root.enterprise.stripeSubscription)
        // console.log('subscription price', this.$root.enterprise.stripeSubscription.plan.amount / 100)
        this.fixingBillingUpdate = true
        if (this.$root.enterprise.stripeSubscription) {
          this.$root.callFunction('changeSubscriptionTier', {
            tierID: this.$root.enterprise.tier,
            enterpriseID: this.$root.enterpriseID,
          })
            .then(() => {
              this.fixingBillingUpdate = false
              this.billingUpdateDialog = false
            })
            .catch(err => {
              this.fixingBillingUpdate = false
              alert(err)
            })
        } else {
          this.$root.callFunction('createSubscriptionCheckoutSession', {
            enterprise: this.$root.enterpriseID,
            tier: this.$root.enterprise.tier,
            returnURL: window.location.href,
          })
            .then(resp => {
              window.location = resp.data.url
            })
            .catch(err => {
              this.fixingBillingUpdate = false
              alert(err)
            })
        }

        // this.billingUpdateDialog = false
      },
      acceptInvite: function () {
        this.$root.acceptingStaffInvite = true
        this.$root.callFunction('acceptStaffInvite', {
          enterprise: this.$root.enterpriseID,
          staffInviteID: this.$root.staffInviteID,
          userID: this.$root.userID,
        })
      },
      handleDrop: function (e) {
        this.droppingFile = false
        const dt = e.dataTransfer
        this.droppedFiles = Array.from(dt.files)
      },
    },
  }

</script>

<style>
:root {
  --headerColor: white;
  --backColor1: #f7f7f7fa;
  --backColor2: #f7f7f7e0;
  --highlightColor: #1e90ff;
  --cardColorLight: #FFFFFF;
  --cardColorDark: #293237;
  --highlightColorRGB: 30, 144, 255;
  --paneColor1: white;
  --paneColor2: #f4f4f4;
  --shadowColor: #0003;
  --logoLeftMargin: 28px;
  --headerTextColor: #292929;
  --font: 'Inter', sans-serif;
}

.v-application--wrap {
  min-height: 0 !important;
  height: -webkit-fill-available !important;
}

.v-application {
  font-family: var(--font);
  font-display: swap;
}

.mobile {
  display: none !important;
}
@media screen and (max-width: 600px) {
  .mobile {
    display: unset !important;
  }
  .desktop {
    display: none !important;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.v-expansion-panels {
  z-index: 0 !important;
}

.v-btn {
  text-transform: none;
  font-weight: 500;
}

#app .v-btn.v-size--default {
  font-size: 1rem;
}

/* .v-text-field--outlined:not(.theme--dark) > .v-input__control > .v-input__slot > fieldset {
  margin-top: 6px;
}

.v-text-field--outlined:not(.v-input--is-focused, .error--text) > .v-input__control > .v-input__slot > fieldset {
  border-color: #ddd;
  transition: 0.2s;
}

.v-text-field--outlined:not(.v-input--is-focused, .error--text):hover > .v-input__control > .v-input__slot > fieldset {
  border-color: #888 !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot > fieldset::before {
  content: "";
  position: absolute;
  border-radius: 8px;
  transition: 0.2s;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  opacity: 0.1;
  box-shadow: 0px 0px 0px 0px var(--highlightColor);
}

.v-text-field--outlined.v-input--is-focused > .v-input__control > .v-input__slot > fieldset::before {
  border-radius: 8px;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  box-shadow: 0px 0px 0px 5px var(--highlightColor);
}

.v-text-field--outlined:not(.theme--dark) > .v-input__control > .v-input__slot > fieldset > legend {
  background-color: white !important;
  top: -8px;
  position: absolute;
} */

.action-button {
  background-color: var(--highlightColor);
  padding: 8px 0;
  border-radius: 4px;
  margin: 12px;
  width: 150px;
  cursor: pointer;
  opacity: 0.8;
  box-shadow: 1px 1px 6px var(--shadowColor);
  text-align: center;
  transition: 0.3s;
  display: inline-flex;
  justify-content: center;
  position: relative;
  text-decoration: none;
}

.action-button p, .action-button, .action-button a {
  color: white;
}

.action-button:hover {
  opacity: 1;
}

.action-dropdown {
  background-color: var(--paneColor1);
  color: white;
  border-radius: 4px;
  overflow: hidden;
  margin: 12px;
  width: 150px;
  height: fit-content;
  max-height: 37px;
  cursor: pointer;
  box-shadow: 1px 1px 6px var(--shadowColor);
  text-align: center;
  transition: 0.3s;
  display: block;
  justify-content: center;
  position: relative;
  z-index: 5;
}

.action-dropdown:hover {
  max-height: 400px;
  margin-bottom: -400px;
}

.action-dropdown > * {
  padding: 8px;
  box-sizing: border-box;
  background-color: var(--highlightColor);
  transition: 0.2s;
  opacity: 0.8;
  width: 100%;
  border-radius: 0px;
}

.action-dropdown > *:hover,
.action-dropdown:hover > *:first-child {
  opacity: 1;
}

.message-enter,
.message-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.message-leave-active {
  position: absolute;
}

#search-results > .project-wrapper {
  opacity: 0.8;
}

#search-results > .project-wrapper.active {
  opacity: 1;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--highlightColor);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.daysLeftIcon {
  margin: 2px auto;
  padding: 0px 8px;
  border-radius: 8px;
  width: fit-content;
  font-weight: bold;
  text-decoration: none;
}

.list-enter-active {
  transition: all 0.3s;
}

.list-leave-active {
  transition: all 0s;
}

.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.transition-item {
  transition: all 0.3s;
  display: inline-block;
}
.transition-item-enter, .transition-item-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.transition-item-leave-active {
  position: absolute;
}

.user-tag {
  border-radius: 4px;
  white-space: nowrap;
  color: dodgerblue;
}

.fromSelf .user-tag {
  color: white;
}

.navigation-list .v-list-item--active {
  background-color: #FFF2;
}

.v-sheet.v-card:not(.v-sheet--outlined), .multiLineInput {
  box-shadow: 0px 1px 1px 0px rgba(0,0,0,.1),0px 2px 10px 0px rgba(0,0,0,.05);
}

.v-card {
  margin: 16px 0;
}

.theme--dark.v-sheet.v-card:not(.v-sheet--outlined) {
  /* border: 1px solid #0003; */
  box-shadow: 0 0 8px 0 #0003;
}
/* 
.v-sheet.v-card .v-card__title {
  padding: 32px;
}

.v-sheet.v-card .v-card__text {
  padding-top: 32px;
}

.v-sheet.v-card .v-card__title + .v-sheet.v-card .v-card__text {
  padding: 0 32px 32px 32px;
} */

/* .v-text-field--outlined:not(.v-input--is-focused) fieldset {
  border-width: 1px !important;
}

.v-text-field--outlined.v-input--is-focused fieldset {
  border: 4px solid var(--highlightColor) !important;
} */

/* .v-text-field--outlined.v-input--is-focused fieldset {
  z-index: 1 !important;
}

.v-text-field--outlined.v-input--is-focused fieldset:before {
  content: "";
  position: absolute;
  top: -5px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 6px;
  z-index: -1 !important;
  opacity: 0.5;
  outline: 4px solid red !important;
} */

/* .v-text-field--outlined fieldset:after {
  content: "";
  z-index: 0;
  position: absolute;
  z-index: 0;
  top: -5px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0.5;
  transition: 0.2s;
  border-radius: 9px;
}

.v-text-field--outlined.v-input--is-focused fieldset:after {
  top: -9px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 4px solid var(--highlightColor) !important;
} */

/* .v-text-field--outlined.v-input--dense .v-label--active {
  transform: translateY(-16px) scale(0.85) !important;
} */

/* .v-text-field--outlined legend {
  width: 0 !important;
}

.v-text-field--filled.v-input--dense:not(.v-text-field--single-line).v-text-field--outlined input {
  padding: 0;
} */

h1 {
  font-size: 3.3125rem;
  line-height: 1.15em;
  letter-spacing: -1.4px;
  font-weight: 800;
}
h2 {
  font-size: 2.25rem;
  line-height: 1.5em;
  letter-spacing: -1px;
  font-weight: 800;
}
h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
  letter-spacing: -0.8px;
  font-weight: 700;
}
h4 {
  font-size: 1.125rem;
  line-height: 1.4em;
  letter-spacing: -0.55px;
  font-weight: 700;
}
h5 {
  font-size: 1rem;
  line-height: 1.3em;
  letter-spacing: -0.4px;
  font-weight: 600;
}
h6 {
  font-size: 0.85rem;
}

div.v-speed-dial {
  z-index: auto;
}

.height-100 {
  height: 100%;
}

.v-btn--fab.v-btn--absolute, .v-btn--fab.v-btn--fixed {
  z-index: inherit;
}

.v-menu__content.v-menu__content--fixed {
  border-radius: 16px;
}

.v-navigation-drawer:not(.project-sidebar) .v-icon.v-icon {
  font-size: 24px;
}

.theme--light.v-btn:hover::before {
  opacity: 0.08 !important;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.p-sticky {
  position: sticky;
}

.p-static {
  position: static;
}

.p-inherit {
  position: inherit;
}

.p-initial {
  position: initial;
}

.p-unset {
  position: unset;
}

.material-picker-material-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  transition: 0.3s;
}
</style>
