import Vue from 'vue'
import Router from 'vue-router'
import { App } from '@capacitor/app'
import { auth } from './firebaseConfig'
import * as Sentry from '@sentry/vue'

Vue.use(Router)

App.addListener('appUrlOpen', function (data) {
  const slug = data.url.split('.com').pop()
  if (slug) {
    router.push({
      path: slug,
    })
  }
})

let pendingChatOpen = null
let pendingReaderOpen = null
let pendingReaderElementOpen = null

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/components/Login'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/components/404Page'),
    },
    {
      path: '/',
      name: 'TaskPageHome',
      component: () => import('@/components/extras/TaskPage'),
      props: { path: 'formfactories' },
    },
    {
      path: '/dashboard',
      name: 'DashboardDefaultPage',
      component: () => import('@/components/DashboardPage'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/dashboard/:view',
      name: 'DashboardPage',
      component: () => import('@/components/DashboardPage'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/chat/:uuid',
      name: 'Chat',
    },
    {
      path: '/scanner',
      name: 'Scanner',
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/reader/:uuid',
      name: 'Reader',
    },
    {
      path: '/wiki/:task',
      name: 'Wiki',
      component: () => import('@/components/extras/TaskReader'),
      props: route => ({ navigationTask: route.params.task, fullscreen: true }),
    },
    {
      path: '/wiki',
      name: 'WikiHome',
      component: () => import('@/components/extras/TaskReader'),
      props: route => ({ fullscreen: true }),
    },
    {
      path: '/docs/:task',
      name: 'Docs',
      component: () => import('@/components/extras/TaskReader'),
      props: route => ({ navigationTask: route.params.task, fullscreen: true }),
    },
    {
      path: '/docs',
      name: 'DocsHome',
      component: () => import('@/components/extras/TaskReader'),
      props: route => ({ fullscreen: true }),
    },
    {
      path: '/reader/:task/:element',
      name: 'ReaderElement',
    },
    {
      path: '/projects',
      name: 'MyProjectsPage',
      component: () => import('@/components/MyProjectsPage'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/parts',
      name: 'MyPartsPage',
      component: () => import('@/components/MyPartsPage'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/projects/:uuid/:view?',
      name: 'ProjectPage',
      component: () => import('@/components/project/ProjectPage'),
      props: true,
    },
    {
      path: '/analytics',
      name: 'AnalyticsPage',
      component: () => import('@/components/metrics/Analytics'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/task-draft-preview/:path',
      name: 'TaskDraftPreview',
      component: () => import('@/components/extras/TaskPage'),
      props: route => ({ path: route.params.path, previewMode: true }),
    },
    {
      path: '/info/:path',
      name: 'TaskPageOld',
      component: () => import('@/components/extras/TaskPage'),
      props: true,
    },
    {
      path: '/materials/:name',
      name: 'MaterialPage',
      component: () => import('@/components/extras/MaterialPage'),
      props: true,
    },
    {
      path: '/products',
      name: 'AllProducts',
      component: () => import('@/components/extras/ProductSquarePage'),
    },
    {
      path: '/products/:category',
      name: 'CategoryProducts',
      component: () => import('@/components/extras/ProductSquarePage'),
      props: true,
    },
    {
      path: '/products/:category/:path',
      name: 'ProductPage',
      component: () => import('@/components/extras/ProductComponent'),
      props: true,
    },
    {
      path: '/products/:category/:path/:sku',
      name: 'ProductVariantPage',
      component: () => import('@/components/extras/ProductComponent'),
      props: true,
    },
    {
      path: '/submit',
      name: 'Submit Files',
      component: () => import('@/components/SubmitPage'),
    },
    {
      path: '/counting',
      name: 'Counting',
      component: () => import('@/components/extras/Counting'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/collection',
      name: 'DesignCollectionHome',
      component: () => import('@/components/extras/DesignCollectionInterface'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/collection/:id',
      name: 'DesignCollection',
      component: () => import('@/components/extras/DesignCollectionInterface'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/design/:id',
      name: 'Design',
      component: () => import('@/components/extras/Design'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: 'Workspaces',
      path: '/workspaces',
      component: () => import('@/components/extras/Tasks'),
      props: { kanban: true },
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: 'Machines',
      path: '/machines',
      component: () => import('@/components/machines/MachineDashboard'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/playground',
      name: 'Playground',
      component: () => import('@/components/extras/Playground'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/landing',
      name: 'Landing',
      component: () => import('@/components/extras/Landing'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/inventory',
      name: 'Inventory',
      component: () => import('@/components/extras/Inventory'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/inventory/:id',
      name: 'Inventory Item',
      component: () => import('@/components/extras/Inventory'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/queue',
      name: 'Print Queue',
      component: () => import('@/components/extras/PrintQueue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: 'Clients',
      path: '/clients',
      component: () => import('@/components/staff/CustomerList'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/customers/:email',
      name: 'User',
      component: () => import('@/components/staff/User'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/company/:company',
      name: 'Company',
      component: () => import('@/components/staff/Company'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/organizations/:uuid',
      name: 'Organization',
      component: () => import('@/components/staff/Organization'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: 'Projects',
      path: '/project-list',
      component: () => import('@/components/staff/ProjectList'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: 'Account',
      path: '/account',
      component: () => import('@/components/extras/Account'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      name: 'EnterpriseSignup',
      path: '/get-started',
      component: () => import('@/components/staff/EnterpriseSignup'),
    },
    {
      name: 'Subscriptions',
      path: '/subscriptions/:id',
      component: () => import('@/components/staff/CheckoutSession'),
      props: true,
    },
    {
      path: '/staff',
      component: () => import('@/components/staff/Staff'),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          name: 'Dashboard',
          path: 'dashboard',
          component: () => import('@/components/staff/StaffDashboard'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'catalog',
          name: 'CatalogPage',
          component: () => import('@/components/staff/Catalog'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'products',
          name: 'ProductsCatalog',
          component: () => import('@/components/staff/Products'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Media',
          path: 'media',
          component: () => import('@/components/extras/Media'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'pricing',
          name: 'PricingPage',
          component: () => import('@/components/staff/Pricing'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'emails',
          name: 'EmailsPage',
          component: () => import('@/components/staff/Emails'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'machines',
          name: 'MachinesPane',
          component: () => import('@/components/staff/Machines'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'integrations',
          name: 'Integrations',
          component: () => import('@/components/staff/Integrations'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'server-status',
          name: 'Server Status',
          component: () => import('@/components/staff/ServerStatus'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'enterprises',
          name: 'Enterprises',
          component: () => import('@/components/staff/Enterprises'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'contact-suggestions',
          name: 'Contact Suggestions',
          component: () => import('@/components/staff/ContactSuggestions'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'features',
          name: 'Features',
          component: () => import('@/components/staff/Features'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'general',
          name: 'General',
          component: () => import('@/components/staff/Features'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'billing',
          name: 'Billing',
          component: () => import('@/components/staff/Billing'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'roles',
          name: 'Roles',
          component: () => import('@/components/staff/Roles'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/sitemaptest',
      name: 'SitemapTest',
      meta: {
        externalRedirect: `https://api.incept3d.com/meta/${encodeURIComponent(window.location.origin)}/sitemap.xml`,
      }
    },
    {
      path: '/:path',
      name: 'TaskPage',
      component: () => import('@/components/extras/TaskPage'),
      props: true,
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.name.startsWith('Dashboard')) document.getElementById('router-content').scroll({ top: 0 })
  },
})

router.beforeEach((to, from, next) => {
  // Redirect externally if needed
  if (to.meta.externalRedirect) {
    const url = to.meta.externalRedirect
    window.location.replace(url)
    return
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const currentUser = auth.currentUser || window.currentUser
  const homepageDestination = (router.app.enterprise || window.enterprise.enterprise).homepageDestination || 'task'

  // Inform Sentry of the current route
  Sentry.setContext('route', {
    name: to.name,
    path: to.path,
    query: to.query,
    params: to.params,
  })

  let isStaff = false
  if (router.app && router.app.isStaff !== undefined) isStaff = !!router.app.isStaff
  else if (window.userToken) {
    const legacyClaims = (window.userToken || {}).claims || {}
    const enterpriseClaims = (legacyClaims.enterprises || {})[window.enterpriseID]
    const claims = enterpriseClaims || legacyClaims
    isStaff = claims.access >= 5
  }

  const inviteCode = new URLSearchParams(window.location.search).get('invite')
  if (inviteCode && router.app && to.name !== 'Login' && isStaff) {
    router.app.logout()
    return next(from)
  }

  if (requiresAuth && !currentUser && !(to.name === 'Home' || to.name === 'DashboardDefaultPage' || to.name === 'DashboardPage')) router.push({ name: 'Login', query: { redirect: to.path } })
  else if (to.name === 'TaskPageHome' && isStaff) router.push({ name: 'DashboardDefaultPage' })
  else {
    if (to.name === 'TaskPageHome' && !isStaff && homepageDestination !== 'task') {
      if (homepageDestination === 'login') next({ name: 'Login' })
      else if (homepageDestination === 'submit') next({ name: 'Submit Files' })
      else if (homepageDestination === 'formfactories-signup') next({ name: 'EnterpriseSignup' })
      else next()
    } else if (to.name === 'Chat') {
      next(from)
      if (router.app.openChat) router.app.openChat(to.params.uuid)
      else pendingChatOpen = to.params.uuid
    } else if (to.name === 'Scanner') {
      next(from)
      if (router.app.navigation) router.app.navigation.qrScannerOpen = true
      else window.openScanner = true
    } else if (to.name === 'Reader') {
      next(from)
      if (router.app.openReader) router.app.openReader(to.params.uuid)
      else pendingReaderOpen = to.params.uuid
    } else if (to.name === 'ReaderElement') {
      next(from)
      if (router.app.openReader) router.app.openReader(to.params.task, to.params.element)
      else {
        pendingReaderOpen = to.params.task
        pendingReaderElementOpen = to.params.element
      }
    } else {
      if (to.name === 'Login' && currentUser && isStaff && !inviteCode) next('/')
      else if (to.name === 'Login' && currentUser && !isStaff && !inviteCode) next('/')
      else if ((to.name === 'Home' || to.name === 'DashboardDefaultPage' || to.name === 'DashboardPage') && !isStaff) next('/')
      else next()
    }
  }
})

router.afterEach((to, from) => {
  if (pendingChatOpen && router.app.openChat) {
    router.app.openChat(pendingChatOpen)
    pendingChatOpen = null
  }

  if (pendingReaderOpen && router.app.openReader) {
    router.app.openReader(pendingReaderOpen, pendingReaderElementOpen)
    pendingReaderOpen = null
    pendingReaderElementOpen = null
  }
})

export default router
