import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'
import { getAuth, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { Capacitor } from '@capacitor/core'

const LIVE_OVERRIDE = false

// intitalize firebase
let config
if (process.env.NODE_ENV === 'production' || LIVE_OVERRIDE) {
  config = {
    apiKey: 'AIzaSyCpZataVT9bf5edexyq9bpGn-lAel4OSE4',
    authDomain: 'formfactories-incept3d.firebaseapp.com',
    databaseURL: 'https://formfactories-incept3d.firebaseio.com',
    projectId: 'formfactories-incept3d',
    storageBucket: 'formfactories-incept3d.appspot.com',
    messagingSenderId: '19356599543',
    measurementId: 'G-NRKD95QPY1',
    experimentalAutoDetectLongPolling: true,
  }
} else {
  config = {
    apiKey: "AIzaSyDqVR-44IiwdDZAKeipptUr-_sgwDrB52c",
    authDomain: "formfactories-dev.firebaseapp.com",
    projectId: "formfactories-dev",
    storageBucket: "formfactories-dev.appspot.com",
    messagingSenderId: "821361985623",
    appId: "1:821361985623:web:0c354f435badda3a4ebb12",
    measurementId: "G-E6XBP33KDD",
    experimentalAutoDetectLongPolling: true
  }
}

const firebaseApp = initializeApp(config)

// firebase utils
const db = getFirestore(firebaseApp)
const auth = Capacitor.isNativePlatform() ? initializeAuth(firebaseApp, {
  persistence: indexedDBLocalPersistence,
}) : getAuth()
const storage = getStorage(firebaseApp)

export { db, auth, storage }
