import moment from 'moment'
import { ref, getDownloadURL, uploadBytesResumable, getMetadata } from 'firebase/storage'

const uuid = require('uuid')
const innertext = require('innertext')
const sanitizeUrl = require('@braintree/sanitize-url').sanitizeUrl

export default {
  methods: {
    sanitize: function (url) {
      return sanitizeUrl(url)
    },
    listLayout: function (list) {
      // Generate all tasks and lists
      const numColumns = !isNaN(parseInt(list.megaMenuColumns)) && parseInt(list.megaMenuColumns) > 0 ? parseInt(list.megaMenuColumns) : 1
      const tasks = this.$root.publicTasks.filter(x => x.list === list.uuid).map(task => ({
        order: +task.readerSortOrder || Infinity,
        length: 1,
        tasks: [{
          text: this.primaryElementText(task),
          link: `/${this.$root.publicTaskIDToPathMap[task.uuid]}`,
          image: this.primaryImage(task),
          bold: list.megaMenuSubcategories && list.megaMenuSubcategories.length,
        }],
      }))
      const lists = (list.megaMenuSubcategories || []).map(cat => ({
        length: this.$root.publicTasks.filter(x => x.list === cat).length,
        order: +list.megaMenuSubcategorySortOrders[cat] || 0,
        tasks: [{
          text: (this.$root.taskLists.find(x => x.uuid === cat) || {}).name,
        }].concat(this.$root.publicTasks.filter(x => x.list === cat).sort((a, b) => (+a.readerSortOrder || Infinity) - (+b.readerSortOrder || Infinity)).map((task, t) => ({
          text: this.primaryElementText(task),
          link: task.material ? (`/materials/${this.$root.materialIDToPathMap[task.uuid]}`) : (`/${this.$root.publicTaskIDToPathMap[task.uuid]}`),
          image: this.primaryImage(task),
          endOfList: t === this.$root.publicTasks.filter(x => x.list === cat).length - 1,
        }))),
      }))

      // Arrange into columns
      const allElems = tasks.concat(lists).sort((a, b) => a.order - b.order)
      const result = []
      for (let col = 1; col <= numColumns; col++) {
        const colElems = []
        const expectedLength = allElems.map(x => x.length).reduce((a, b) => a + b, 0) / (numColumns - col + 1)
        let currentLength = 0
        let distanceFromExpected = Infinity

        while (allElems.length && Math.abs((currentLength + allElems[0].length) - expectedLength) < distanceFromExpected) {
          distanceFromExpected = Math.abs((currentLength + allElems[0].length) - expectedLength)
          const elem = allElems.shift()
          currentLength += elem.length
          colElems.push(elem)
        }
        result.push(colElems.flatMap(e => e.tasks))
      }
      return result
    },
    calculateNextDueDate: function (task) {
      if (!task.dueDate) return null
      const originalDueDate = new Date(`${task.dueDate}T23:59:59`)
      if (!task.repeat) return originalDueDate
      else {
        if (task.repeat === 'Daily') {
          while (originalDueDate < Date.now()) originalDueDate.setDate(originalDueDate.getDate() + 1)
        } else if (task.repeat === 'Weekly') {
          while (originalDueDate < Date.now()) originalDueDate.setDate(originalDueDate.getDate() + 7)
        } else if (task.repeat === 'Biweekly') {
          while (originalDueDate < Date.now()) originalDueDate.setDate(originalDueDate.getDate() + 14)
        } else if (task.repeat === 'Monthly') {
          while (originalDueDate < Date.now()) originalDueDate.setMonth(originalDueDate.getMonth() + 1)
        } else if (task.repeat === 'Quarterly') {
          while (originalDueDate < Date.now()) originalDueDate.setMonth(originalDueDate.getMonth() + 4)
        } else if (task.repeat === 'Yearly') {
          while (originalDueDate < Date.now()) originalDueDate.setFullYear(originalDueDate.getFullYear() + 1)
        }
        return originalDueDate
      }
    },
    taskName: function (task) {
      if (!task) return ''
      if (task.type === 'project') return task.projectLabel || (task.projectIndexShort ? `Project #${task.projectIndexShort}` : task.name)
      if (task.type !== 'task' || task.name) return task.name
      else return this.primaryElementText(task) || 'Untitled'
    },
    primaryElements: function (elements, data) {
      if (elements) return elements.flatMap(e => data[e] ? (data[e].type === 'group' ? this.primaryElements(data[e].taskElements, data) : data[e]) : [])
    },
    primaryElement: function (task) {
      if (!task) return null
      let activeTask = task.published && task.publishedTask ? task.publishedTask : task
      return this.primaryElements(activeTask.taskElements || [], activeTask.taskElementData).filter(e => e && e.text)[0]
    },
    descriptionElement: function (task) {
      const primaryElem = this.primaryElement(task)
      if (task) return this.primaryElements(task.taskElements || [], task.taskElementData).filter(e => e && e !== primaryElem && e.text)[0]
    },
    primaryElementText: function (task) {
      if (task) return this.elementInnerText(this.primaryElement(task))
      else return ''
    },
    elementInnerText: function (element) {
      if (element) return innertext(element.text)
      else return ''
    },
    taskDescription: function (task) {
      if (!task) return ''
      else if (task.description) return task.description
      else return this.elementInnerText(this.descriptionElement(task))
    },
    getElementImages: function (element, data) {
      if (element.type === 'group') return (element.taskElements || []).flatMap(e => this.getElementImages(data[e], data))
      else return (element.attachments || []).filter(a => a.url && a.name && (a.name.toLowerCase().endsWith('jpg') || a.name.toLowerCase().endsWith('gif') || a.name.toLowerCase().endsWith('png') || a.name.toLowerCase().endsWith('jpeg')))
    },
    primaryImage: function (task) {
      if (task && task.thumbnail) return { url: task.thumbnail }
      return (task.taskElements || []).flatMap(e => this.getElementImages(task.taskElementData[e], task.taskElementData))[0]
    },
    youtubeMatch: function (url, inQuotes) {
      const youtubeMatch = inQuotes ? /"https:\/\/(?:www.)?youtu(?:be.com\/watch\?v=|.be\/)([a-zA-Z0-9_-]{5,20})"/g : /https:\/\/(?:www.)?youtu(?:be.com\/watch\?v=|.be\/)([a-zA-Z0-9_-]{5,20})/g
      const results = []
      let ytmatch
      while ((ytmatch = youtubeMatch.exec(url)) !== null) {
        if (ytmatch && ytmatch[1]) results.push(ytmatch[1].replace('&nbsp', ''))
      }
      return results
    },
    youtubeShortsMatch: function (url, inQuotes) {
      const youtubeMatch = inQuotes ? /"https:\/\/(?:www.)?youtube.com\/shorts\/([a-zA-Z0-9_-]{5,20})[^"\s]*"/g : /https:\/\/(?:www.)?youtube.com\/shorts\/([a-zA-Z0-9_-]{5,20})[^"\s]*/g
      const results = []
      let ytmatch
      while ((ytmatch = youtubeMatch.exec(url)) !== null) {
        if (ytmatch && ytmatch[1]) results.push(ytmatch[1].replace('&nbsp', ''))
      }
      return results
    },
    loomMatch: function (url, inQuotes) {
      const loomMatch = inQuotes ? /"https:\/\/www.loom.com\/share\/([a-zA-Z0-9_-]{20,40})"/g : /https:\/\/www.loom.com\/share\/([a-zA-Z0-9_-]{20,40})/g
      const results = []
      let lmatch
      while ((lmatch = loomMatch.exec(url)) !== null) {
        if (lmatch && lmatch[1]) results.push(lmatch[1].replace('&nbsp', ''))
      }
      return results
    },
    dayDisplay: function (date, shorten) {
      const today = new Date()
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)

      if (date && shorten) {
        if (date.getYear() === today.getYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate()) return 'Today'
        if (date.getYear() === tomorrow.getYear() && date.getMonth() === tomorrow.getMonth() && date.getDate() === tomorrow.getDate()) return 'Tomorrow'
      }
      return moment(date).format('ddd MMM Do')
    },
    clientDescription: function (project) {
      if (project.clientEmail === 'support@3dhubs.com') return `HUBS - ${project.po}`
      else if (project.organization && this.$root.getOrganizationName(project.organization, '')) return this.$root.getOrganizationName(project.organization)
      else if (project.checkout && project.checkout.billing && project.checkout.billing.company) return project.checkout.billing.company
      else if (project.checkout && project.checkout.billing && project.checkout.billing.name) return project.checkout.billing.name
      else if (project.checkout && project.checkout.shipping && project.checkout.shipping.company) return project.checkout.shipping.company
      else if (project.checkout && project.checkout.shipping && project.checkout.shipping.name) return project.checkout.shipping.name
      else if (project.clientEmail && this.$root.getAccountReferenceName(project.clientEmail, '')) return this.$root.getAccountReferenceName(project.clientEmail)
      else if (project.clientCompany) return project.clientCompany
      else if (project.clientName) return project.clientName
      else if (project.clientInfo) return (project.clientInfo ? project.clientInfo.split('\n')[0] : '')
      else if (project.zohoContact) return project.zohoContact.contact_name + (project.clientInfo ? ' - ' + project.clientInfo.split('\n')[0] : '')
      else return project.clientEmail
    },
    clientOrganizationDescription: function (project) {
      if (project.clientEmail === 'support@3dhubs.com') return `HUBS - ${project.po}`
      else if (project.organization && this.$root.getOrganizationName(project.organization, '')) return this.$root.getOrganizationName(project.organization)
      else if (project.checkout && project.checkout.billing && project.checkout.billing.company) return project.checkout.billing.company
      else if (project.checkout && project.checkout.shipping && project.checkout.shipping.company) return project.checkout.shipping.company
      else if (project.clientCompany) return project.clientCompany
      else return ''
    },
    clientUserDescription: function (project) {
      if (project.clientEmail === 'support@3dhubs.com') return `HUBS - ${project.po}`
      else if (project.checkout && project.checkout.billing && project.checkout.billing.name) return project.checkout.billing.name
      else if (project.checkout && project.checkout.shipping && project.checkout.shipping.name) return project.checkout.shipping.name
      else if (project.clientEmail && this.$root.getAccountReferenceName(project.clientEmail, '')) return this.$root.getAccountReferenceName(project.clientEmail)
      else if (project.clientName) return project.clientName
      else if (project.clientInfo) return (project.clientInfo ? project.clientInfo.split('\n')[0] : '')
      else if (project.zohoContact) return project.zohoContact.contact_name + (project.clientInfo ? ' - ' + project.clientInfo.split('\n')[0] : '')
      else return project.clientEmail
    },
    moment: t => moment(t),
    uploadAndGetURL: async function (file, folder = 'attachments', progressCallback) {
      return new Promise((resolve, reject) => {
        const filename = file.name || (this.generateUUID() + (file.type ? '.' + file.type.split('/').pop() : ''))
        const metadata = { contentDisposition: `attachment; filename="${filename}"` }
        const storageRef = ref(this.$root.storage, `enterprises/${this.$root.enterpriseID}/${folder}/${this.generateUUID()}/${filename}`)
        const uploadTask = uploadBytesResumable(storageRef, file, metadata)
        uploadTask.on('state_changed',
          function (snapshot) {
            if (progressCallback) progressCallback((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          },
          function (err) {
            reject(err)
          },
          function () {
            resolve(getDownloadURL(uploadTask.snapshot.ref))
          },
        )
      })
    },
    dateTimeDisplay: t => moment(t).format('MMMM Do YYYY, h:mm a'),
    dateDisplay: t => moment(t).format('MMMM Do YYYY'),
    daysUntilDue: function (project) {
      if (!project) project = this.project
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const dueDate = Date.parse(`${project.dueDate}T00:00:00`)
      return parseInt((dueDate - today) / (1000 * 60 * 60 * 24))
    },
    confirm: function (text) {
      return confirm(text)
    },
    modelFitsInBox: function (model, project, box) {
      const modelStats = this.getConvertedModelStats(model, project)
      if (modelStats && modelStats.size) return (this.boxFitsInBox(modelStats.size, box))
      else return null
    },
    checkoutEligible: function (project) {
      if (!project.quoteStatus) return true
      if (project.quoteStatus === 'approved') return true
      if (project.quoteStatus === 'requoted') return true
      if (!this.$root.enableProjectPricing) return false
      const itemsLength = (project.models.length + (project.addons || []).length)
      const invalidPrices = project.models.map(m => this.priceModel(m, project)).filter(x => !(x && !x.error && x.checkoutEligible))
      return itemsLength > 0 && !invalidPrices.length
    },
    getConvertedModelStats: function (model, project) {
      let modelStats
      if (project.modelData && project.modelData[model.uuid]) {
        modelStats = project.modelData[model.uuid]
      } else if (this.$root.state.models[model.uuid] && this.$root.state.models[model.uuid].stats) {
        modelStats = this.$root.state.models[model.uuid].stats
      } else {
        return null
      }

      const result = {
        volume: modelStats.volume,
        surfaceArea: modelStats.surfaceArea,
        supports: modelStats.supports ? { volume: modelStats.supports.volume, area: modelStats.supports.area } : null,
        size: modelStats.size ? { x: modelStats.size.x, y: modelStats.size.y, z: modelStats.size.z } : null,
      }
      if (model.units === 'inch') {
        result.volume *= 16387.1
        result.surfaceArea *= 645.16
        if (result.supports) {
          result.supports.volume *= 16387.1
          result.supports.area *= 645.16
        }
        if (result.size) {
          result.size.x *= 25.4
          result.size.y *= 25.4
          result.size.z *= 25.4
        }
      }
      if (model.units === 'cm') {
        result.volume *= 1000
        result.surfaceArea *= 100
        if (result.supports) {
          result.supports.volume *= 1000
          result.supports.area *= 100
        }
        if (result.size) {
          result.size.x *= 10
          result.size.y *= 10
          result.size.z *= 10
        }
      }
      return result
    },
    fillDefaultPricingOptions: function (pricingOptions, model) {
      const args = pricingOptions || {}
      const options = {
        catalog: args.catalog || this.$root.catalog,
        catalogByName: args.catalogByName || this.$root.catalogByName,
        machines: args.machines || this.$root.machineAvailability,
        noSupports: args.noSupports || false,
        noCleaning: args.noCleaning || false,
        guessCleaningTime: args.guessCleaningTime || false,
        cleaning: args.cleaning || this.$root.production.cleaning,
        bulkDiscountsEnabled: !!(args.sales ? args.sales.bulkDiscountsEnabled : this.$root.production.sales.bulkDiscountsEnabled),
        bulkTimeNumerator: (args.sales ? args.sales.bulkTimeNumerator : this.$root.production.sales.bulkTimeNumerator) || 0,
        bulkTimeDenominatorConstant: (args.sales ? args.sales.bulkTimeDenominatorConstant : this.$root.production.sales.bulkTimeDenominatorConstant) || 0,
        bulkTimeConstant: (args.sales ? args.sales.bulkTimeConstant : this.$root.production.sales.bulkTimeConstant) || 0,
        automaticQuoting: args.automaticQuoting || this.$root.production.sales?.automaticQuoting
      }

      // Set model quantity
      if (args.quantity && parseInt(args.quantity) && parseInt(args.quantity) > 0) options.quantity = parseInt(args.quantity)
      else if (model.quantity && parseInt(model.quantity) && parseInt(model.quantity) > 0) options.quantity = parseInt(model.quantity)
      else options.quantity = 1

      return options
    },
    leadTimeDisplay: function (project) {
      const leadTime = this.estimateLeadTime(project)
      if (project.leadDays) return project.leadDays
      if (leadTime.text.startsWith('-Infinity')) return ''
      else if (project.requestExpedition && leadTime.expeditionEligible) return leadTime.expeditedText
      else if (leadTime.text === '1 business day') {
        const timeInSanDiego = new Date(this.$root.currentTime.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }))
        if (timeInSanDiego.getHours() >= 15 || timeInSanDiego.getDay() > 5) return 'Ships next business day'
        else return 'Ships today (if ordered by 3pm PT)'
      }
      else return leadTime.text
    },
    estimateShipment: function (itemDimensions) {
      // Common box sizes in inches (with dimensions in ascending order)
      const BOXES = this.$root.configPublic.commonBoxes || [
        { width: 6, height: 6, depth: 6 },
        { width: 8, height: 8, depth: 8 },
        { width: 10, height: 10, depth: 10 },
        { width: 4, height: 12, depth: 12 },
        { width: 6, height: 12, depth: 16 },
        { width: 13, height: 15, depth: 19 },
        { width: 12, height: 12, depth: 12 },
        { width: 12, height: 12, depth: 24 },
        { width: 20, height: 20, depth: 24 },
      ].sort((a, b) => a.width * a.height * a.depth - b.width * b.height * b.depth)

      let totalVol = 0
      let totalWeight = 0
      let minWidth = 0
      let minHeight = 0
      let minDepth = 0

      // Calculate total volume and weight
      itemDimensions.forEach(d => {
        const width = isNaN(+d.width) || +d.width < 0 ? 0 : +d.width
        const height = isNaN(+d.height) || +d.height < 0 ? 0 : +d.height
        const depth = isNaN(+d.depth) || +d.depth < 0 ? 0 : +d.depth
        const weight = isNaN(+d.weight) || +d.weight < 0 ? 0 : +d.weight
        const quantity = isNaN(+d.quantity) || +d.quantity < 0 ? 0 : +d.quantity

        totalVol += width * height * depth * quantity
        totalWeight += weight * quantity

        const sizes = [width, height, depth].sort((a, b) => a - b)
        minWidth = Math.max(minWidth, sizes[0])
        minHeight = Math.max(minHeight, sizes[1])
        minDepth = Math.max(minDepth, sizes[2])
      })

      // Find box that fits minimum dimensions and has enough volume
      totalVol *= 1.1 // Add 10% to account for packing material
      totalWeight *= 1.1 // Add 10% to account for packing material
      let box = BOXES.find(b => b.width >= minWidth && b.height >= minHeight && b.depth >= minDepth && b.width * b.height * b.depth >= totalVol)
      if (!box) {
        box = { width: minWidth, height: minHeight, depth: minDepth}
        const endVolume = minWidth * minHeight * minDepth
        if (endVolume < totalVol) box.width *= totalVol / endVolume
      }

      if (isNaN(totalWeight) || totalWeight < 1) totalWeight = 1
      return {
        width: box.width.toFixed(2),
        height: box.height.toFixed(2),
        depth: box.depth.toFixed(2),
        weight: totalWeight.toFixed(2),
      }
    },
    estimateLeadTime: function (project) {
      const onlyProducts = !!((project.addons || []).filter(a => a.sku).length && !(project.addons || []).filter(a => !a.sku).length && !(project.attachments || []).length && !(project.models || []).length)
      if (onlyProducts) return { text: '1 business day', expeditionEligible: false, expeditedText: '', days: 1 }

      const modelsByTech = {}
      const allLeadTimes = []
      const tooltips = []
      project.models.forEach(model => {
        const tech = model.requestedSettings.Technology
        if (!modelsByTech[tech]) modelsByTech[tech] = []
        modelsByTech[tech].push(model)
      })

      Object.keys(modelsByTech).forEach(techName => {
        const tech = ((this.$root.catalogByName.Technology || {}).children || {})[this.matchOption('Technology', techName)]
        if (tech) {
          if (tech.leadTimeStrategy === 'capacity') {
            const rampStart = isNaN(+tech.leadTimeCapacityRampStart) ? 0.1 : +tech.leadTimeCapacityRampStart
            const rampEnd = isNaN(+tech.leadTimeCapacityRampEnd) ? 0.3 : +tech.leadTimeCapacityRampEnd
            const rampDuration = isNaN(+tech.leadTimeCapacityRampDuration) ? 7 : +tech.leadTimeCapacityRampDuration
            const uptime = isNaN(+tech.leadTimeCapacityEfficiency) ? (10 / 24) : (+tech.leadTimeCapacityEfficiency / 100)
            const rangeInflator = isNaN(+tech.leadTimeInflator) ? 1.15 : (+tech.leadTimeInflator / 100) + 1

            const capacities = {}
            this.$root.machineAvailability.forEach(p => {
              capacities[p.uuid] = p.capacity || 0
            })

            // Determine which printers each in-house model should be printed on
            const modelStrategies = {}
            const inHouseModels = modelsByTech[techName]
            inHouseModels.forEach(model => {
              const modelTime = this.estimatedPrintTime(model, project) * parseInt(model.quantity || 1) / 24
              const printFits = this.getModelPrintFits(model, project)
              const modelPrinters = Object.keys(printFits.map).filter(k => printFits.map[k] && capacities[k])

              // Account for when no machines are available
              if (!modelPrinters.length) {
                const printerUUIDs = this.$root.production.machines.filter(m => printFits.map[m.uuid] !== undefined && capacities[m.uuid]).map(m => m.uuid)
                printerUUIDs.reverse()
                if (printerUUIDs.length) modelPrinters.push(printerUUIDs.find(p => capacities[p]))
              }

              // Calculate printer priorities for model proportional to printer capacities
              const totalCapacity = modelPrinters.map(p => capacities[p]).reduce((a, b) => a + b, 0)
              const printerPriorities = {}
              modelPrinters.forEach(p => {
                printerPriorities[p] = capacities[p] / totalCapacity
              })
              modelStrategies[model.uuid] = { time: modelTime, priorities: printerPriorities }
            }, this)

            // Simulate a printing schedule for models made in-house
            let days = 0
            const timeout = 365
            while (Object.values(modelStrategies).filter(x => x.time).length && days < timeout) {
              Object.keys(capacities).forEach(p => {
                let timeToDisburse = capacities[p] * Math.min((days + 1) * (rampEnd - rampStart) / rampDuration, rampEnd) * uptime
                let prioritiesTotalled = Object.values(modelStrategies).map(x => x.priorities[p]).filter(x => x).reduce((a, b) => a + b, 0)
                if (prioritiesTotalled) {
                  Object.values(modelStrategies).filter(m => m.priorities[p]).forEach(model => {
                    const modelTimeGranted = (model.priorities[p] / prioritiesTotalled) * timeToDisburse
                    const modelTimeDisbursed = Math.min(model.time, modelTimeGranted)
                    timeToDisburse -= modelTimeDisbursed
                    prioritiesTotalled -= model.priorities[p]
                    model.time -= modelTimeDisbursed
                  })
                }
              })
              days++
            }
            if (days === timeout) return { text: '', expeditionEligible: false, expeditedText: '', tooltip: null }

            // Create a lead time range
            allLeadTimes.push([days, parseInt((days + 2) * rangeInflator)])
          }

          if (tech.leadTimeStrategy === 'price') {
            const totalPrice = +modelsByTech[techName].map(x => this.priceModel(x, project).price * (isNaN(+x.quantity) ? 1 : parseInt(x.quantity))).reduce((a, b) => a + b, 0)

            // Calculate lead time from volume tiers
            const minStopValue = Math.min(...tech.leadTimePriceStops.map(x => !+x.value ? Infinity : +x.value).filter(x => x > totalPrice))
            const stop = tech.leadTimePriceStops.find(x => (+x.value === minStopValue) || (!+x.value && minStopValue === Infinity))
            if (stop) {
              if (stop.structure === 'range') allLeadTimes.push([parseInt(stop.leadDaysMin) || 0, parseInt(stop.leadDaysMax) || 0])
              if (stop.structure === 'function') {
                const minLeadTime = (totalPrice * (isNaN(+stop.leadDaysMultiplier) ? 1 : +stop.leadDaysMultiplier)) + (isNaN(+stop.leadDaysOffset) ? 1 : +stop.leadDaysOffset)
                allLeadTimes.push([minLeadTime, minLeadTime * (isNaN(+stop.leadDaysInflator) ? 1.15 : +stop.leadDaysInflator)])
              }
              if (stop.tooltip) tooltips.push(stop.tooltip)
            }
          }

          if (tech.leadTimeStrategy === 'volume') {
            // Calculate total cubic inch model volume for technology
            let totalVolume = 0
            modelsByTech[techName].forEach(model => {
              const convertedStats = this.getConvertedModelStats(model, project)
              const volume = convertedStats ? convertedStats.volume : 0
              const cubicInches = (volume / 16387.1) * parseInt(model.quantity)
              totalVolume += cubicInches
            })

            // Calculate lead time from volume tiers
            const minStopValue = Math.min(...tech.leadTimeVolumeStops.map(x => !+x.value ? Infinity : +x.value).filter(x => x > totalVolume))
            const stop = tech.leadTimeVolumeStops.find(x => (+x.value === minStopValue) || (!+x.value && minStopValue === Infinity))
            if (stop) {
              if (stop.structure === 'range') allLeadTimes.push([parseInt(stop.leadDaysMin) || 0, parseInt(stop.leadDaysMax) || 0])
              if (stop.structure === 'function') {
                const minLeadTime = (totalVolume * (isNaN(+stop.leadDaysMultiplier) ? 1 : +stop.leadDaysMultiplier)) + (isNaN(+stop.leadDaysOffset) ? 1 : +stop.leadDaysOffset)
                allLeadTimes.push([minLeadTime, minLeadTime * (isNaN(+stop.leadDaysInflator) ? 1.15 : +stop.leadDaysInflator)])
              }
              if (stop.tooltip) tooltips.push(stop.tooltip)
            }
          }
        }
      })

      let lowerRange = Math.max(...allLeadTimes.map(x => x[0]))
      let upperRange = Math.max(...allLeadTimes.map(x => x[1]))
      let rangeUnits = 'business days'

      const filteredTooltips = [...new Set(tooltips)]
      const tooltip = filteredTooltips.join('\n\n')

      // Convert to weeks if needed
      if (lowerRange >= 10) {
        lowerRange = (lowerRange / 7).toFixed()
        upperRange = (upperRange / 7).toFixed()
        rangeUnits = 'weeks'
      }

      let text
      let expeditedText = ''
      if (lowerRange === upperRange) text = `${lowerRange} ${rangeUnits}`
      else text = `${lowerRange}-${upperRange} ${rangeUnits}`
      const expeditionEligible = lowerRange !== -Infinity
      if (expeditionEligible) expeditedText = text.split(' ')[0].endsWith('3') ? 'Ships next business day' : 'Ships as soon as possible'
      return { text, expeditionEligible, expeditedText, tooltip }
    },
    // Returns the size of a nozzle in mm, given a nozzle string
    parseNozzleSize: function (nozzle) {
      const defaultSize = 0.4
      if (!nozzle) return defaultSize
      if (typeof nozzle === 'number') return nozzle
      if (typeof nozzle !== 'string') return defaultSize
      const trimmed = nozzle.trim()

      // Handle normal mm sizes
      if (trimmed.endsWith('mm')) {
        const size = parseFloat(trimmed)
        if (isNaN(size) || size <= 0) return defaultSize
        return size
      }

      // Convert thousandths of an inch to mm
      if (trimmed.startsWith('T')) {
        const size = parseFloat(trimmed.trim().split('T')[1]) * 0.0254
        if (isNaN(size) || size <= 0) return defaultSize
        return size
      }

      // Assume mm otherwise
      const size = parseFloat(trimmed)
      if (isNaN(size) || size <= 0) return defaultSize
      return size
    },
    // The changing nozzles also changes the number of walls used, so adjust for that
    filamentNozzleFudgeFactor: function (nozzle) {
      if (nozzle >= 0.4) return (0.785714 * nozzle ** 2) - (0.0328571 * nozzle) + 0.8874286
      else return (3.72117 * nozzle ** 2) - (2.29071 * nozzle) + 1.3208968
    },
    estimatedPrintTime: function (model, project, pricingOptions) {
      const options = this.fillDefaultPricingOptions(pricingOptions, model)

      // Get material costs as approximation of printTime
      const materialUsed = this.calculateMaterialCosts(model, project, options).materialUsed

      // Get relevant properties
      const tech = options.catalogByName.Technology.children[this.matchOption('Technology', model.requestedSettings.Technology)]
      const nozzleDiameterOverride = this.matchOption('Nozzle', model.requestedSettings.Nozzle, true)?.nozzleDiameter
      const nozzle = (nozzleDiameterOverride && !isNaN(nozzleDiameterOverride)) ? +nozzleDiameterOverride : this.parseNozzleSize(model.requestedSettings.Nozzle)
      let layerRes = (tech?.restrictions?.['Layer Height']?.length === 0 ? false : (this.matchOption('Layer Height', model.requestedSettings['Layer Height'], true)?.layerHeightValue || model.requestedSettings['Layer Height'])) || (tech?.pricingStyle === 'filament' ? '0.2mm' : '0.05mm')
      const convertedSize = (this.getConvertedModelStats(model, project) || {}).size || {}

      // Fallbacks
      if (isNaN(layerRes)) layerRes = parseFloat(layerRes) || (tech?.pricingStyle === 'filament' ? 0.2 : 0.05)

      // TODO: Actually calculate it precisely
      let time
      if (tech?.pricingStyle === 'filament') {
        const nozzleMultiplier = (0.4 / nozzle) * this.filamentNozzleFudgeFactor(nozzle)
        const layerMultipler = 0.2 / layerRes
        time = materialUsed * nozzleMultiplier * layerMultipler / (tech?.printSpeed || 100)
      } else {
        const layerMultipler = 0.05 / layerRes
        const size = ((convertedSize.x * convertedSize.y * convertedSize.z) || 0) ** (1 / 3)
        time = size * layerMultipler / (tech?.printSpeed || 100)
      }

      // Factor by machine time multipler
      const printFits = this.getModelPrintFits(model, project, options).best
      const printer = options.machines.find(x => x.uuid === printFits)

      let packingMultiplier = 1
      if (printer && convertedSize.x && convertedSize.y && convertedSize.z) {
        // Determine how many "layers of models" we need
        const printerXY = printer.size.x * printer.size.y
        const sortedModelSizes = [convertedSize.x, convertedSize.y, convertedSize.z].sort((a, b) => a - b)
        
        // Compare largest 2 dimensions to printer XY area
        const perLayer = Math.floor(printerXY / (sortedModelSizes[0] * sortedModelSizes[1]))
        const layers = Math.ceil(options.quantity / perLayer)
        packingMultiplier = 1 - ((1 - (layers / options.quantity)) * (tech?.packingMultiplierStrength || 0))
      }

      // Multiply by machine multiplier, or 2x if model doesn't fit on any printers
      return time * packingMultiplier * (printer ? printer.printTimeMultiplier || 1 : 2)
    },
    calculateSetupCosts: function (model, project, options) {
      const tech = options.catalogByName.Technology.children[this.matchOption('Technology', model.requestedSettings.Technology)]
      if (!tech) return { setupCost: 0, tech: '' }
      const fixedCost = (+tech.basePrice || 0) / options.quantity
      return { setupCost: fixedCost, tech: tech?.displayName }
    },
    calculateCleaningCosts: function (model, project, options) {
      const result = { estimatingCleaningTime: model.cleaningTime === undefined || options.guessCleaningTime }

      // Get relevant model settings
      const tech = options.catalogByName.Technology.children[this.matchOption('Technology', model.requestedSettings.Technology)] || {}
      const material = options.catalogByName.Material.children[this.matchOption('Material', model.requestedSettings.Material)] || {}
      result.manualCleaningTime = (+model.cleaningTime || 0) / 60

      // Unit conversions
      const convertedStats = this.getConvertedModelStats(model, project)
      const surfaceArea = convertedStats.surfaceArea || 0
      const supportVolume = convertedStats.supports ? convertedStats.supports.volume : 0
      const supportArea = convertedStats.supports ? convertedStats.supports.area : 0

      // Estimate cleaning time
      let guessedTime = 0
      guessedTime += (surfaceArea ** 2) * (options.cleaning['SA2_' + tech.uuid] || 0)
      guessedTime += (surfaceArea) * (options.cleaning['SA1_' + tech.uuid] || 0)
      guessedTime += (surfaceArea ** 0.5) * (options.cleaning['SAsr_' + tech.uuid] || 0)
      guessedTime += (supportVolume ** 2) * (options.cleaning['SPV2_' + tech.uuid] || 0)
      guessedTime += (supportVolume) * (options.cleaning['SPV1_' + tech.uuid] || 0)
      guessedTime += (supportVolume ** 0.5) * (options.cleaning['SPVsr_' + tech.uuid] || 0)
      guessedTime += (supportArea ** 2) * (options.cleaning['SPA2_' + tech.uuid] || 0)
      guessedTime += (supportArea) * (options.cleaning['SPA1_' + tech.uuid] || 0)
      guessedTime += (supportArea ** 0.5) * (options.cleaning['SPAsr_' + tech.uuid] || 0)
      guessedTime += (options.cleaning['C_' + tech.uuid] || 0)
      guessedTime *= (options.cleaning['S_' + tech.uuid] || 1)
      guessedTime *= (+material.cleaningDifficulty || 1)
      if (guessedTime < 0) guessedTime = 0
      result.estimatedCleaningTime = guessedTime

      // Calculate final costs
      result.cleaningTime = result.estimatingCleaningTime ? result.estimatedCleaningTime : result.manualCleaningTime
      result.cleaningRate = +tech.cleaningRate || 0
      result.cleaningCost = result.cleaningTime * result.cleaningRate
      return result
    },

    /**
     * Determine what infill value to use for calculations based on model settings.
     * @param {object} model - The model object to calculate infill for
     * @returns {number} - The infill percentage to use for calculations as a fraction (0-1)
     */
    determineInfill: function (model) {
      const DEFAULT_INFILL_PERCENTAGE = 20
      let infillPercent = DEFAULT_INFILL_PERCENTAGE

      const infillLookup = this.matchOption('Infill', model.requestedSettings.Infill, true) || { infillPercentage: DEFAULT_INFILL_PERCENTAGE }
      if (infillLookup.infillPercentage) infillPercent = +infillLookup.infillPercentage
      else if (!isNaN(infillLookup.displayName)) infillPercent = +infillLookup.displayName
      else if (!isNaN(parseFloat(infillLookup.displayName))) infillPercent = parseFloat(infillLookup.displayName)
      if (isNaN(infillPercent) || infillPercent < 0 || infillPercent > 100) infillPercent = DEFAULT_INFILL_PERCENTAGE
      
      return infillPercent / 100
    },
    calculateMaterialCosts: function (model, project, pricingOptions) {
      const options = this.fillDefaultPricingOptions(pricingOptions, model)
      const techMatch = this.matchOption('Technology', model.requestedSettings.Technology)
      const tech = techMatch || options.catalogByName.Technology.children[0]?.name
      const techLookup = options.catalogByName.Technology.children[techMatch]
      let materialWeight = 0
      const infill = this.determineInfill(model)

      // Add some assumptions if we don't have all data
      let material = options.catalogByName.Material.children[this.matchOption('Material', model.requestedSettings.Material)]
      if (!material || (material.restrictions.Technology && !material.restrictions.Technology.includes(tech))) {
        material = options.catalog.find(x => x.name === 'Material').children.filter(x => x.restrictions.Technology.includes(tech))[0]
      }

      // Unit conversions
      const convertedStats = this.getConvertedModelStats(model, project)
      const volume = convertedStats ? convertedStats.volume : 0
      const surfaceArea = convertedStats ? convertedStats.surfaceArea : 0
      const supportVolume = convertedStats && convertedStats.supports ? convertedStats.supports.volume : 0
      const supportArea = convertedStats && convertedStats.supports ? convertedStats.supports.area : 0

      // Calculate support density
      let supportDensity = techLookup?.pricingStyle === 'filament' ? (1/2) : (1/6)
      if (!isNaN(parseFloat(techLookup?.supportDensity))) supportDensity = parseFloat(techLookup?.supportDensity) / 100

      // Calculate material costs
      if (model.usedFilamentCM3) {
        materialWeight = model.usedFilamentCM3 * (+material.density || 1)
      } else {
        if (techLookup && techLookup.pricingStyle === 'filament') {
          const shellVolume = Math.min(surfaceArea * 1.2, volume)
          const infillVolume = (volume - shellVolume) * infill
          const printSupportVolume = !options.noSupports ? (supportArea + (supportVolume * supportDensity)) : 0
          materialWeight = (shellVolume + infillVolume + printSupportVolume) * +(material.density || 1) / 1000
        } else if (techLookup && techLookup.pricingStyle === 'resin') {
          const printSupportVolume = !options.noSupports ? (supportArea + (supportVolume * supportDensity)) : 0
          const padVolume = techLookup?.useResinSupportPad ? (volume ** (2 / 3)) : 0
          materialWeight = (volume + printSupportVolume + padVolume) * +(material.density || 1) / 1000
        } else if (techLookup && techLookup.pricingStyle === 'subtractive') {
          materialWeight = convertedStats?.size?.x * convertedStats?.size?.y * convertedStats?.size?.z * +(material.density || 1) / 1000
        } else {
          materialWeight = volume * (+material?.density || 1) / 1000
        }
      }

      const matDifficulty = +(material || {}).printingDifficulty || 1
      const matPrice = (+(material || {}).price || 0) / 1000

      return {
        materialCost: materialWeight * matDifficulty * matPrice,
        weight: materialWeight,
        materialUsed: materialWeight * matDifficulty,
      }
    },
    calculateExtraCosts: function (model, project, options, basePrice) {
      // Calculate any extra costs from property price adjustments
      const result = { breakdown: [], extraCost: 0 }

      // Consider properties that are compitable with the technology
      const tech = options.catalogByName.Technology.children[this.matchOption('Technology', model.requestedSettings.Technology)]
      const propertiesToCheck = Object.keys(model.requestedSettings || {}).filter(x => tech?.restrictions?.[x]?.length !== 0)
      propertiesToCheck.forEach(setting => {
        const match = this.matchOption(setting, model.requestedSettings[setting], true)
        const adjustments = match?.priceAdjustments?.filter(x => x.amount !== '' && !(x.type === 'constant' && !+x.amount)) || []
        adjustments.forEach(adj => {
          const amount = adj.type === 'constant' ? +adj.amount : ((+basePrice * +adj.amount) - +basePrice)
          if (adj.label) result.breakdown.push(`${adj.label}: $${amount.toFixed(2)}`)
          else result.breakdown.push(`${match?.displayName || match?.name} ${setting}: $${amount.toFixed(2)}`)
          result.extraCost += amount
        })
      })
      return result
    },
    calculateMarkup: function (model, project, options) {
      // Calculate a printer-based markup
      const bestPrinterUUID = this.getModelPrintFits(model, project, options).best
      const bestPrinter = options.machines.find(x => x.uuid === bestPrinterUUID)
      if (bestPrinter && bestPrinter.markup) return { markup: (bestPrinter.markup / 100) + 1, markupPercentage: bestPrinter.markup }
      else return { markup: 1, markupPercentage: 0 }
    },
    calculateTimeCosts: function (model, project, options) {
      // Get necessary info for calculations
      const tech = options.catalogByName.Technology.children[this.matchOption('Technology', model.requestedSettings.Technology)]
      const modelStats = this.getConvertedModelStats(model, project)
      const bestPrinterUUID = this.getModelPrintFits(model, project, options).best
      const bestPrinter = options.machines.find(x => x.uuid === bestPrinterUUID)

      // Calculate print times
      let printTime
      if (modelStats.estimatedTime) printTime = modelStats.estimatedTime / 3600
      else printTime = this.estimatedPrintTime(model, project, options)

      // Adjust with bulk time discounts
      const bulkTimeMultiplier = options.bulkDiscountsEnabled ? (options.bulkTimeNumerator / (options.quantity + options.bulkTimeDenominatorConstant) + options.bulkTimeConstant) : 1
      const printTimeMultiplier = bestPrinter && bestPrinter.printTimeRateMultiplier ? bestPrinter.printTimeRateMultiplier : 1
      const timeCost = printTime * +(tech?.price || 0) * bulkTimeMultiplier * printTimeMultiplier

      return { timeCost, printTime, bulkTimeMultiplier, bestPrinter }
    },
    determineInstantPricingEligibility: function (model, project, options) {
      const result = { eligible: false, sizes: [] }
      if (!options.automaticQuoting) return result
      const material = options.catalogByName.Material.children[this.matchOption('Material', model.requestedSettings.Material)]

      // Check if any properties are incompatible with instant pricing
      const settings = Object.keys(model.requestedSettings)
      for (let i = 0; i < settings.length; i++) {
        const propertyDetails = this.matchOption(settings[i], model.requestedSettings[settings[i]], true)
        if (propertyDetails?.preventInstantPricing) return result
      }

      const eligibleMachines = material ? (material.machinesInstantPriceEligible || []) : []
      const printFits = this.getModelPrintFits(model, project, options)

      // Check to make sure model doesn't break any min/max tech size restrictions
      const tech = this.matchOption('Technology', model.requestedSettings.Technology, true)
      const originalSize = this.getConvertedModelStats(model, project)?.size
      if (!tech || !originalSize) return result
      const size = Object.values(originalSize).sort((a, b) => a - b)
      const maxSize = tech.preventInstantQuotingOverSize ? [tech.preventInstantQuotingOverSizeValue1, tech.preventInstantQuotingOverSizeValue2, tech.preventInstantQuotingOverSizeValue3].sort((a, b) => a - b) : [Infinity, Infinity, Infinity]
      const minSize = tech.preventInstantQuotingUnderSize ? [tech.preventInstantQuotingUnderSizeValue1, tech.preventInstantQuotingUnderSizeValue2, tech.preventInstantQuotingUnderSizeValue3].sort((a, b) => a - b) : [0, 0, 0]
      if (size[0] < minSize[0] || size[1] < minSize[1] || size[2] < minSize[2]) {
        return { eligible: false, minSizes: [{ x: tech.preventInstantQuotingUnderSizeValue1, y: tech.preventInstantQuotingUnderSizeValue2, z: tech.preventInstantQuotingUnderSizeValue3 }] }
      }
      if (size[0] > maxSize[0] || size[1] > maxSize[1] || size[2] > maxSize[2]) {
        return { eligible: false, sizes: [{ x: tech.preventInstantQuotingOverSizeValue1, y: tech.preventInstantQuotingOverSizeValue2, z: tech.preventInstantQuotingOverSizeValue3 }] }
      }
      // Check to make sure model fits on a printer
      const bestPrinterUUID = options.automaticQuoting === 'selective' ? eligibleMachines.find(uuid => printFits.map[uuid]) : printFits.best
      const bestPrinter = options.machines.find(x => x.uuid === bestPrinterUUID)
      if (!bestPrinter || !eligibleMachines.includes(bestPrinterUUID)) {
        const sizes = eligibleMachines.map(uuid => options.machines.find(machine => machine.uuid === uuid)).filter(x => x && x.size).map(x => x.size)
        sizes.forEach(size => {
          let largestOfClass = true
          for (let i = 0; i < sizes.length && largestOfClass; i++) {
            const sizeToCompare = sizes[i]
            if (size.x < sizeToCompare.x && size.y < sizeToCompare.y && size.z < sizeToCompare.z) largestOfClass = false
          }
          if (largestOfClass) result.sizes.push(size)
        })
      } else result.eligible = true
      return result
    },
    getModelUnitPrice (model, project) {
      const useInstantPrice = isNaN(parseFloat(model.finalPrice))
      if (useInstantPrice) return +(this.priceModel(model, project).price || 0)
      else return +(model.finalPrice || 0)
    },
    urlToFile(url, filename, mimeType) {
      return fetch(url)
        .then(response => response.blob())
        .then(blob => new File([blob], filename, { type: mimeType }))
    },
    priceModel: function (model, project, pricingOptions) {
      // Run several checks to make sure no required info is missing
      const modelStats = this.getConvertedModelStats(model, project)
      const options = this.fillDefaultPricingOptions(pricingOptions, model)
      if (!options.catalog || !options.catalogByName) return { error: 'Material catalog not yet downloaded' }
      if (!modelStats) return { error: 'Model statistics are still generating' }
      if (!model.requestedSettings) return { error: 'No print settings have been set for this model' }
      if (!model.requestedSettings.Technology) return { error: 'No technology selected' }

      // Calculate 4 factors of a model price
      const setupCosts = this.calculateSetupCosts(model, project, options)
      const cleaningCosts = this.calculateCleaningCosts(model, project, options)
      const materialCosts = this.calculateMaterialCosts(model, project, options)
      const timeCosts = this.calculateTimeCosts(model, project, options)
      const basePrice = setupCosts.setupCost + cleaningCosts.cleaningCost + materialCosts.materialCost + timeCosts.timeCost
      const extraCosts = this.calculateExtraCosts(model, project, options, basePrice)
      const markup = this.calculateMarkup(model, project, options)
      const meta = { ...setupCosts, ...cleaningCosts, ...materialCosts, ...timeCosts, ...extraCosts, ...markup }

      // Determine model instant pricing eligibility
      const eligibility = this.determineInstantPricingEligibility(model, project, options)
      const checkoutEligible = eligibility.eligible
      const checkoutEligibleSizes = eligibility.sizes
      const checkoutEligibleMinSizes = eligibility.minSizes

      // Generate a human-friendly price breakdown
      const breakdown = []
      breakdown.push(`Setup Price (${meta.tech}): $${meta.setupCost.toFixed(2)}`)
      breakdown.push(`Cleaning (${parseInt(meta.cleaningTime * 60)}m): $${meta.cleaningCost.toFixed(2)}`)
      breakdown.push('Material' + (meta.materialUsed ? ' (' + meta.materialUsed.toFixed(0) + 'g)' : '') + ': $' + meta.materialCost.toFixed(2))
      breakdown.push('Print Time' + (meta.printTime ? ' (' + meta.printTime.toFixed(1) + 'h)' : '') + ': $' + meta.timeCost.toFixed(2))
      breakdown.push(...extraCosts.breakdown)
      if (meta.markupPercentage) breakdown.push('Markup (' + meta.markupPercentage + '%): $' + ((meta.setupCost + meta.cleaningCost + meta.materialCost + meta.timeCost) * (meta.markup - 1)).toFixed(2))

      // Return prices
      const price = +((basePrice + extraCosts.extraCost) * meta.markup).toFixed(2)
      const formattedPrice = this.formatPrice(price)
      return { price, formattedPrice, checkoutEligible, checkoutEligibleSizes, checkoutEligibleMinSizes, breakdown, meta }
    },
    timeDisplay: function (seconds) {
      const maxNumUnits = 2
      let timeLeftToDisplay = +seconds
      const timeUnitsSingular = ['second', 'min', 'hour', 'day', 'month']
      const timeUnitsPlural = ['seconds', 'min', 'hours', 'days', 'months']
      const timeUnitsDivider = [1, 60, 60 * 60, 60 * 60 * 24, 60 * 60 * 24 * 30]

      // Display the time left using the two largest time units with values >= 1
      const displayUnits = []
      let nextIndexToTry = Math.max(...timeUnitsDivider.map((u, i) => (timeLeftToDisplay / u) >= 1 ? i : -1))
      let exhaustedUnits = nextIndexToTry === -1
      while (!exhaustedUnits && (nextIndexToTry - displayUnits.length) >= 0 && displayUnits.length < maxNumUnits) {
        const timeValues = timeUnitsDivider.map(u => timeLeftToDisplay / u)
        const unitValue = parseInt(timeValues[nextIndexToTry])
        if (unitValue === 0) exhaustedUnits = true
        else {
          displayUnits.push(unitValue + ' ' + (unitValue === 1 ? timeUnitsSingular[nextIndexToTry] : timeUnitsPlural[nextIndexToTry]))
          timeLeftToDisplay -= unitValue * timeUnitsDivider[nextIndexToTry]
          nextIndexToTry--
        }
      }

      // Join to form time remaining string
      return displayUnits.join(' ')
    },
    getModelPrintFits: function (model, project, pricingOptions) {
      const options = this.fillDefaultPricingOptions(pricingOptions, model)
      const modelStats = this.getConvertedModelStats(model, project)
      if (modelStats && modelStats.size) {
        // Orient model in 90º increments in each dimension
        const orientations = [
          { x: modelStats.size.x, y: modelStats.size.y, z: modelStats.size.z },
          { x: modelStats.size.x, y: modelStats.size.z, z: modelStats.size.y },
          { x: modelStats.size.y, y: modelStats.size.x, z: modelStats.size.z },
          { x: modelStats.size.y, y: modelStats.size.z, z: modelStats.size.x },
          { x: modelStats.size.z, y: modelStats.size.x, z: modelStats.size.y },
          { x: modelStats.size.z, y: modelStats.size.y, z: modelStats.size.x },
        ]

        let bestPrinter
        const printFitMap = {}
        const technology = model.requestedSettings.Technology
        const matchedTech = ((options.catalogByName.Technology || {}).children || {})[technology] || { name: 'Unknown' }
        const printers = options.machines.filter(p => p.technology === matchedTech.name)
        for (let p = 0; p < printers.length; p++) {
          let fits = false
          for (let o = 0; o < orientations.length; o++) {
            if (orientations[o].x < printers[p].size.x && orientations[o].y < printers[p].size.y && orientations[o].z < printers[p].size.z) fits = true
          }

          // Create list of valid printers
          if (fits) {
            printFitMap[printers[p].uuid] = true
            if (!bestPrinter) bestPrinter = printers[p].uuid
          } else printFitMap[printers[p].uuid] = false
        }

        return {
          best: bestPrinter,
          map: printFitMap,
        }
      } else return { bestPrinter: null, texts: [], map: {} }
    },
    generateSummary: function (model) {
      const settings = this.$root.optionsToShow(model)
      let result = ''
      for (let i = 0; i < settings.length; i++) {
        const visibilityOverride = (model.summaryVisibilityOverrides || {})[settings[i].name]
        const visibile = visibilityOverride === undefined ? !settings[i].advanced : visibilityOverride
        if (!visibile) continue
        const optionPick = model.requestedSettings[settings[i].name]
        const optionPickMatch = optionPick ? this.matchOption(settings[i].name, optionPick, true) : null
        if (optionPick && optionPickMatch) {
          result = `${result}${settings[i].name}: ${optionPickMatch.displayName}`
          if (settings[i].name === 'Color' && model.colorChanges?.filter(x => x.color)?.length) {
            result += '/' + model.colorChanges.filter(x => x.color).map(x => x.color).join('/')
          }
          result += '\n'
        }
      }
      return result
    },
    generateLegacySummary: function (model) {
      const settings = this.$root.optionsToShow(model)
      let result = ''
      for (let i = 0; i < settings.length; i++) {
        const visibilityOverride = (model.summaryVisibilityOverrides || {})[settings[i].name]
        const visibile = visibilityOverride === undefined ? !settings[i].advanced : visibilityOverride
        if (!visibile) continue
        const optionPick = model.requestedSettings[settings[i].name]
        const optionPickMatch = optionPick ? this.matchOption(settings[i].name, optionPick) : null
        if (optionPick && optionPickMatch) {
          result = `${result}${settings[i].name}: ${optionPick}`
          if (settings[i].name === 'Color' && model.colorChanges?.filter(x => x.color)?.length) {
            result += '/' + model.colorChanges.filter(x => x.color).map(x => x.color).join('/')
          }
          result += '\n'
        }
      }
      return result
    },
    getVisiblePrintOptions: function (model, advanced = true) {
      const options = this.$root.catalog.filter(x => !(x.advanced && !advanced))
      const result = []
      const picked = {}
      const restrictions = []

      // Go through options linearly and add children that are not restricted
      for (let i = 0; i < options.length; i++) {
        const option = options[i]
        const childrenToAdd = []
        const optionPick = this.matchOptionFromModel(option.name, model)

        option.children.forEach(c => {
          let compatible = c.active

          // Check if selected options aren't compatible with this option's restrictions
          if (c.restrictions) {
            Object.keys(c.restrictions).forEach(r => {
              if (picked[r] && !c.restrictions[r].includes(picked[r])) compatible = false
            })
          }

          // Check if selected options' restrictions make this option incompatible
          restrictions.forEach(r => {
            if (r[option.name] && !r[option.name].includes(c.name)) compatible = false
          })

          if (compatible) childrenToAdd.push(c.name)
        })

        // Add option if not restricted
        if (childrenToAdd.length > 0) {
          result.push({
            title: option.name,
            members: childrenToAdd,
          })

          if (optionPick && childrenToAdd.includes(optionPick)) {
            picked[option.name] = optionPick
            restrictions.push(this.$root.catalogByName[option.name].children[optionPick].restrictions)
          } else break
        }
      }

      return result
    },
    setModelPrintSetting: function (model, option, pickInput) {
      if (pickInput) {
        // Find option
        let pick
        if (this.$root.catalogByName[option] && this.$root.catalogByName[option].children[pickInput]) pick = this.$root.catalogByName[option].children[pickInput]
        else if (this.$root.catalogByName[option]) {
          Object.values(this.$root.catalogByName[option].children).forEach(child => {
            if (pickInput.toLowerCase() === child.name.toLowerCase() || (child.synonyms && child.synonyms.map(s => s.toLowerCase()).includes(pickInput.toLowerCase()))) {
              pick = child
            }
          })
        }

        if (pick) {
          // Fill in default settings
          if (pick.defaultSettings) {
            Object.keys(pick.defaultSettings).forEach(s => {
              model.requestedSettings[s] = pick.defaultSettings[s]
            })
          }

          // Remove incompatible settings
          Object.keys(pick.restrictions || {}).forEach(r => {
            const currentSetting = model.requestedSettings[r]
            if (currentSetting && !pick.restrictions[r].includes(currentSetting)) {
              const newSetting = pick?.defaultSettings?.[r] || pick.restrictions[r][0]
              if (newSetting) model.requestedSettings[r] = newSetting
              else this.$delete(model.requestedSettings, r)
            }
          })
        }

        // Keep track of old summary to see if we can update
        const oldSummary = this.generateSummary(model)
        const legacySummary = this.generateLegacySummary(model)

        // Set the setting
        this.$set(model.requestedSettings, option, pick ? pick.name : pickInput)

        // Update the summary if needed
        if ((model.defaultSummary && model.summary === model.defaultSummary) || oldSummary === model.summary || legacySummary === model.summary || model.summary === undefined || model.summary.length === 0) {
          const summary = this.generateSummary(model)
          model.summary = summary
          model.defaultSummary = summary
        }

        // Handle material being clicked without color
        if (option === 'Material' && pick) {
          const picked = model.requestedSettings.Color
          if (!picked || !pick.restrictions.Color.includes(picked)) {
            const defaultColor = pick.restrictions.Color.find(x => this.matchOption('Color', x, true)?.active)
            if (defaultColor) this.setModelPrintSetting(model, 'Color', defaultColor)
          }
        }
      } else {
        // Keep track of old summary to see if we can update
        const oldSummary = this.generateSummary(model)
        const legacySummary = this.generateLegacySummary(model)

        // Delete the setting
        this.$delete(model.requestedSettings, option)

        // Update the summary if needed
        if ((model.defaultSummary && model.summary === model.defaultSummary) || oldSummary === model.summary || legacySummary === model.summary || model.summary === undefined || model.summary.length === 0) {
          const summary = this.generateSummary(model)
          model.summary = summary
          model.defaultSummary = summary
        }
      }
    },
    formatPrice: function (price) {
      if (!isNaN(price)) {
        let finalPrice = price

        // Clip at two digits after period
        finalPrice = `$${Number.parseFloat(finalPrice).toFixed(2)}`

        // Add commas for long prices
        finalPrice = finalPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return finalPrice
      } else {
        return price
      }
    },
    checkIfPresetIsPicked: function (model, preset) {
      let picked = true
      const data = this
      Object.keys(preset.printSettings).forEach(function (setting) {
        const thisPicked = data.checkIfOptionIsPicked(model, setting, preset.printSettings[setting])
        if (!thisPicked && (data.$root.showAdvanced || (!data.$root.catalogByName[setting].advanced && !data.$root.catalogByName[setting].children[preset.printSettings[setting]].advanced))) {
          picked = false
        }
      })
      return picked
    },
    checkIfOptionIsPicked: function (model, optionName, pickName) {
      if (!model.requestedSettings || !model.requestedSettings[optionName]) return false
      else return this.matchOption(optionName, model.requestedSettings[optionName]) === pickName
    },
    boxFitsInBox: function (objectToFit, boxToFitInto) {
      if (objectToFit && objectToFit.x && objectToFit.y && objectToFit.z && boxToFitInto && boxToFitInto.x && boxToFitInto.y && boxToFitInto.z) {
        // Add printer fit information
        const orientations = [{
            x: objectToFit.x,
            y: objectToFit.y,
            z: objectToFit.z,
          },
          {
            x: objectToFit.x,
            y: objectToFit.z,
            z: objectToFit.y,
          },
          {
            x: objectToFit.y,
            y: objectToFit.x,
            z: objectToFit.z,
          },
          {
            x: objectToFit.y,
            y: objectToFit.z,
            z: objectToFit.x,
          },
          {
            x: objectToFit.z,
            y: objectToFit.x,
            z: objectToFit.y,
          },
          {
            x: objectToFit.z,
            y: objectToFit.y,
            z: objectToFit.x,
          },
        ]

        let fits = false
        for (let o = 0; o < orientations.length; o++) {
          if (orientations[o].x < boxToFitInto.x &&
            orientations[o].y < boxToFitInto.y &&
            orientations[o].z < boxToFitInto.z
          ) fits = true
        }
        return fits
      } else return null
    },
    openInNewTab: function (link) {
      window.open(link, '_blank')
    },
    carrierIcon: function (number) {
      const carrier = this.guessCarrier(number)
      if (carrier) return `fab fa-${carrier.toLowerCase()}`
      else return ' '
    },
    guessCarrier: function (number) {
      if (!number) return null
      else if (number.startsWith('1Z') || number.startsWith('T') || (number.length === 16 && !isNaN(+number.replace(/ /g, ''))) || (number.length === 9 && !isNaN(+number.replace(/ /g, '')))) return 'UPS'
      else if (number.length === 10 && !isNaN(+number.replace(/ /g, ''))) return 'DHL'
      else if (!isNaN(+number.replace(/ /g, '')) && (number.replace(/ /g, '').length === 12 || number.replace(/ /g, '').length === 15)) return 'Fedex'
      else if (number.startsWith('9') && number.replace(/ /g, '').length >= 20 && number.replace(/ /g, '').length <= 35) return 'USPS'
    },
    statusDisplay: function (project) {
      if (project.shipments && project.shipments.length > 0) {
        const trackingStatuses = Array.from(new Set(project.shipments.map(s => s.tracking && s.tracking.tracking_status ? s.tracking.tracking_status.status : null)))
        if (trackingStatuses.length === 1) {
          switch (trackingStatuses[0]) {
            case 'DELIVERED': return 'Delivered'
            case 'TRANSIT': {
              const trackingETAs = Array.from(new Set(project.shipments.map(s => s.tracking ? s.tracking.eta : null)))
              if (trackingETAs.length === 1 && trackingETAs[0]) return `Arriving ${this.dayDisplay(trackingETAs[0])}`
              else return 'Shipped'
            }
            case 'PRE_TRANSIT': return 'Preparing to ship'
            default: return 'Shipped'
          }
        } else return 'Shipped'
      }
      if (project.status === 4) {
        return 'Under Review'
      } else if (project.status === 5) {
        return 'Upload Files'
      } else {
        if (project.checkout) {
          if (project.status === 0 || project.status === 3) return 'Complete'
          else if (project.status === 1) return 'Preparing for Production'
          else if (project.status === 2) return 'In Production'
        } else {
          if (project.status === 0 || project.status === 3) return 'Archived'
          if (project.status === 1 || project.status === 2) return 'Checkout to proceed with order'
        }
      }
    },
    statusColor: function (project) {
      if (project.shipments && project.shipments.length > 0) {
        const trackingStatuses = Array.from(new Set(project.shipments.map(s => s.tracking && s.tracking.tracking_status ? s.tracking.tracking_status.status : null)))
        if (trackingStatuses.length === 1) {
          switch (trackingStatuses[0]) {
            case 'DELIVERED': return 'limegreen'
            case 'TRANSIT': return 'orange'
            case 'PRE_TRANSIT': return 'yellow'
            default: return 'coral'
          }
        } else return 'coral'
      }
      if (project.status === 4) {
        return 'purple'
      } else {
        if (project.checkout) {
          if (project.status === 0 || project.status === 3) return 'dodgerblue'
          else if (project.status === 1) return 'coral'
          else if (project.status === 2) return 'coral'
        } else {
          if (project.status === 0 || project.status === 3) return 'gray'
          if (project.status === 1 || project.status === 2) return 'orange'
        }
      }
    },
    cleanedColorChanges: function (model, modelData) {
      if (!model) return []
      if (!model.colorChanges) return []
      const changes = JSON.parse(JSON.stringify(model.colorChanges.filter(x => x.color)))
      if (!modelData?.size?.z) return []
      changes.sort((a, b) => {
        if (a.height === b.height || (!a.height && !b.height)) return a.created - b.created
        return a.height - b.height
      })
      while (changes.filter(x => x.height === '').length) {
        let startingIndex = changes.findIndex(x => x.height === '')
        let endingIndex = startingIndex
        while (changes[endingIndex + 1] && changes[endingIndex + 1].height === '') endingIndex++
        const precendingHeight = changes[startingIndex - 1] ? changes[startingIndex - 1].height : 0
        const followingHeight = changes[endingIndex + 1] ? changes[endingIndex + 1].height : modelData.size.z
        const step = (followingHeight - precendingHeight) / (endingIndex - startingIndex + 2)
        for (let i = startingIndex; i <= endingIndex; i++) {
          changes[i].height = precendingHeight + step * (i - startingIndex + 1)
        }
      }
      return changes
    },
    matchOptionFromModel: function (option, model) {
      if (model.requestedSettings?.[option]) return this.matchOption(option, model.requestedSettings[option])
    },
    matchOption: function (option, pickInput = '', returnObject = false) {
      let pick
      const optionChildrenByName = this.$root.catalogByName[option]?.children || {}
      if (optionChildrenByName[pickInput]) return returnObject ? optionChildrenByName[pickInput] : pickInput
      else {
        Object.values(optionChildrenByName).forEach(child => {
          if (pickInput.toLowerCase() === child.name.toLowerCase() || child?.synonyms?.map(s => s.toLowerCase())?.includes(pickInput.toLowerCase())) {
            pick = child
          }
        })
      }
      if (pick) return returnObject ? pick : pick.name
    },
    camelToString: function (input) {
      if (typeof input === 'string' || input instanceof String) {
        const result = input.replace(/([A-Z])/g, ' $1')
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
        return finalResult
      } else if (Array.isArray(input)) {
        const results = []
        for (let i = 0; i < input.length; i++) {
          const result = input[i].replace(/([A-Z])/g, ' $1')
          const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
          results.push(finalResult)
        }
        return results.join(', ')
      }
    },
    printingStats: function (project) {
      if (project.models && project.models.length > 0) {
        return {
          unitsPrinting: project.models.map(mod => mod.qtyPrinting ? (+mod.qtyPrinting < +mod.quantity ? +mod.qtyPrinting : +mod.quantity) : 0).reduce((a, b) => a + b),
          unitsCounted: project.models.map(mod => mod.qtyCounted ? (+mod.qtyCounted < +mod.quantity ? +mod.qtyCounted : +mod.quantity) : 0).reduce((a, b) => a + b),
          totalUnits: project.models.map(mod => +mod.quantity).reduce((a, b) => a + b),
        }
      } else return { unitsPrinting: 0, totalUnits: 0 }
    },
    getColorCode: function (colorName) {
      const color = this.$root.catalogByName.Color.children[colorName]
      if (color) {
        const rgbCode = `rgb(${color.color.r}, ${color.color.g}, ${color.color.b})`
        return rgbCode
      } else return color
    },
    generateUUID: function () { // Public Domain/MIT
      return uuid.v4()
    },
    guessPrinter: function (attachment) {
      if (attachment.gcodeAnalysis && attachment.gcodeAnalysis.tags) {
        if (attachment.gcodeAnalysis.tags.includes('duet')) {
          let nozzlePrefix = ''
          if (attachment.gcodeAnalysis.nozzle === 0.4 ||
            attachment.gcodeAnalysis.nozzle === 0.6 ||
            attachment.gcodeAnalysis.nozzle === 0.8 ||
            attachment.gcodeAnalysis.nozzle === 1.0 ||
            attachment.gcodeAnalysis.nozzle === 1.2) nozzlePrefix = attachment.gcodeAnalysis.nozzle.toString() + ' - '
          if (attachment.gcodeAnalysis.maxPos.x < 400 && attachment.gcodeAnalysis.maxPos.y < 400 && attachment.gcodeAnalysis.maxPos.z < 400) return (nozzlePrefix + 'Hypercube/Modix')
          else if (attachment.gcodeAnalysis.maxPos.x < 500 && attachment.gcodeAnalysis.maxPos.y < 500 && attachment.gcodeAnalysis.maxPos.z < 500) return (nozzlePrefix + 'Supercube/Modix')
          else if (attachment.gcodeAnalysis.maxPos.x < 600 && attachment.gcodeAnalysis.maxPos.y < 600 && attachment.gcodeAnalysis.maxPos.z < 600) return (nozzlePrefix + 'Modix')
          else return 'Unknown Duet Printer'
        } else if (attachment.gcodeAnalysis.tags.includes('prusa')) {
          return 'Prusa'
        } else if (attachment.gcodeAnalysis.tags.includes('sigmax')) {
          return 'Sigmax'
        } else if (attachment.gcodeAnalysis.tags.includes('form')) {
          return 'Form 2'
        } else if (attachment.gcodeAnalysis.tags.includes('sl1')) {
          return 'Prusa SL1'
        } else if (attachment.gcodeAnalysis.tags.includes('factory')) {
          return 'Simplify 3D Plate'
        } else if (attachment.gcodeAnalysis.tags.includes('3mf')) {
          return '3MF Project File'
        } else return 'Unknown Printer'
      } else {
        return 'Unknown Printer'
      }
    },
    // Map array to more accesible hash map
    hashArray: function (arr) {
      const arrayKeys = ['uuid', 'link', 'id', 'number']
      return arr.reduce((map, obj) => {
        if (typeof obj === 'string') map[obj] = obj
        else {
          const key = arrayKeys.find(k => obj[k])
          if (key) map[obj[key]] = obj
          else map[JSON.stringify(obj)] = obj
        }
        return map
      }, {})
    },
    // Get array of differences between two objects
    getChanges: function (oldVal, newVal, path) {
      const curPath = path || []
      const changes = []

      // Check primitive values
      if (newVal !== Object(newVal) || oldVal !== Object(oldVal)) {
        if (newVal !== oldVal) {
          changes.push({
            path: curPath,
            old: oldVal,
            new: newVal,
          })
        }
      } else if (Array.isArray(newVal) && Array.isArray(oldVal)) {
        changes.push(...this.getChanges(this.hashArray(oldVal), this.hashArray(newVal), curPath))
      } else if (typeof newVal === 'object' && typeof oldVal === 'object') {
        const allKeys = [...new Set(Object.keys(newVal).concat(Object.keys(oldVal)))]
        allKeys.forEach(oKey => {
          const oChanges = this.getChanges(oldVal[oKey], newVal[oKey], [...curPath, oKey])
          changes.push(...oChanges)
        })
      }

      return changes
    },
  },
}
